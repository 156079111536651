import {Budget, BudgetPeriodicity} from '../types/Budget'
import {getClosestDailyPeriodDate} from './dateUtils'
import {BUDGET_PERIODICITY_MULTIPLIERS} from '../constants/BudgetConstants'

export const getClosestBudgetPeriodStartDate = (budget: Budget): Date =>
    getClosestDailyPeriodDate(new Date(budget.startDate), budget.periodicityInDays)

export const getClosestBudgetPeriodEndDate = (budget: Budget): Date => {
    const startDate = getClosestBudgetPeriodStartDate(budget)
    const endDate = new Date(startDate)
    endDate.setDate(endDate.getDate() + budget.periodicityInDays)
    return endDate
}

export const getPeriodicityInDays = ({type, customType, unit}: BudgetPeriodicity): number =>
    type === 'Custom' ? BUDGET_PERIODICITY_MULTIPLIERS[type][customType!] * unit : BUDGET_PERIODICITY_MULTIPLIERS[type]