import {
    Typography,
    Grid,
    Card,
    CardContent,
    Box,
    Stack
} from '@mui/material'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import SecurityIcon from '@mui/icons-material/Security'
import HistoryIcon from '@mui/icons-material/History'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import GroupIcon from '@mui/icons-material/Groups'
import PaymentsIcon from '@mui/icons-material/Payments'
import LockIcon from '@mui/icons-material/Lock'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert'
import {ReactNode} from 'react'
import './ChangePlanConfirmationDialogContent.scss'
import {Plan, ChangePlanAction} from '../../../../types/Stripe'
import {CHANGE_PLAN_ACTIONS_INFO, planFeatureValues} from '../../../../constants/LicenseConstants'
import {capitalizeFirstLetter} from '../../../../utils/textUtils'
import {useStripeSubscriptionsContext} from '../../../../context/StripeSubscriptionsContext'
import {formatDateStringToLocaleDate} from '../../../../helpers/DateHelpers'
import {getPlanByPriceId} from '../../../../utils/licenseUtils'

interface FeatureCardProps {
    title: string
    description: string
    fromValue?: string
    toValue?: string
    details?: { icon: ReactNode, text: string }[]
    icon: ReactNode
    planTypeChange?: ChangePlanAction
}

interface ChangePlanConfirmationProps {
    updatedPlan: Plan
    planTypeChange: ChangePlanAction
}

const FeatureCard = ({icon, title, description, details, fromValue, toValue, planTypeChange}: FeatureCardProps) => (
    <Card className='featureCardContainer'>
        <CardContent className='featureCardContentContainer'>
            <Box className='featureCardTitleContainer'>
                {icon}
                <Typography variant='body1'>{title}</Typography>
            </Box>
            <Typography variant='subtitle2' className='featureCardDescription'>{description}</Typography>
            {details && details.map((detail, index) => (
                <Stack direction='row' spacing={1} alignItems='center' key={index} mb={1}>
                    {detail.icon}
                    <Typography variant='subtitle2'>{detail.text}</Typography>
                </Stack>
            ))}
            {fromValue && toValue && (
                <Stack direction='row' spacing={{sm: 1, md: 2, lg: 4, xl: 5}} alignItems='center' justifyContent='center' mt={2} className='featureCardLimits'>
                    <Box>
                        <Typography variant='subtitle2' className='currentFeature'>{fromValue}</Typography>
                        <Typography variant='subtitle2' className='currentFeature limit'>Current limit</Typography>
                    </Box>
                    <ArrowForwardIcon className='featureCardIcon arrow'/>
                    <Box>
                        <Typography variant='subtitle2' className={`newFeature ${planTypeChange}`}>{toValue}</Typography>
                        <Typography variant='subtitle2' className={`newFeature limit ${planTypeChange}`}>New limit</Typography>
                    </Box>
                </Stack>
            )}
        </CardContent>
    </Card>
)

export const ChangePlanConfirmationDialogContent = ({updatedPlan, planTypeChange}: ChangePlanConfirmationProps) => {

    const {currentSubscription} = useStripeSubscriptionsContext()
    const currentPlan = getPlanByPriceId(currentSubscription!.plan.id)

    const upgradeDescription = `You are about to upgrade your plan to ${capitalizeFirstLetter(updatedPlan)}, you will get the new features automatically from today, we will
            charge you the difference in the next billing cycle plus the monthly cost of the plan.`
    const downgradeDescription = `You are about to downgrade your plan to ${capitalizeFirstLetter(updatedPlan)}, you will continue to have access to the features of the ${capitalizeFirstLetter(currentPlan)} plan until ${formatDateStringToLocaleDate(currentSubscription!.current_period_end * 1000)}, after that:`

    return <>
        <Typography variant='body1'
                    mb={4}>{planTypeChange === 'upgrade' ? upgradeDescription : downgradeDescription}</Typography>
        <Grid container spacing={{md: 1, lg: 2, xl: 3}}>
            <Grid item xs={12} sm={6} md={3}>
                <FeatureCard
                    icon={<AutoAwesomeIcon className='featureCardIcon'/>}
                    title={CHANGE_PLAN_ACTIONS_INFO[planTypeChange].card1.title}
                    description={CHANGE_PLAN_ACTIONS_INFO[planTypeChange].card1.description}
                    fromValue={planFeatureValues[currentPlan].api}
                    toValue={planFeatureValues[updatedPlan].api}
                    planTypeChange={planTypeChange}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <FeatureCard
                    icon={<LockOpenOutlinedIcon className='featureCardIcon'/>}
                    title={CHANGE_PLAN_ACTIONS_INFO[planTypeChange].card2.title}
                    description={CHANGE_PLAN_ACTIONS_INFO[planTypeChange].card2.description}
                    details={[
                        {icon: <GroupIcon className='featureCardIcon'/>, text: 'Team management'},
                        {icon: <PaymentsIcon className='featureCardIcon'/>, text: 'Budget control'},
                        {icon: <LockIcon className='featureCardIcon'/>, text: 'Safeguard configuration'}
                    ]}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <FeatureCard
                    icon={<SecurityIcon className='featureCardIcon'/>}
                    title={CHANGE_PLAN_ACTIONS_INFO[planTypeChange].card3.title}
                    description={CHANGE_PLAN_ACTIONS_INFO[planTypeChange].card3.description}
                    details={[
                        {icon: <NotificationImportantIcon className='featureCardIcon'/>, text: 'Security alerts'},
                        {icon: <CrisisAlertIcon className='featureCardIcon'/>, text: 'Risk reporting'}
                    ]}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <FeatureCard
                    icon={<HistoryIcon className='featureCardIcon'/>}
                    title={CHANGE_PLAN_ACTIONS_INFO[planTypeChange].card4.title}
                    description={CHANGE_PLAN_ACTIONS_INFO[planTypeChange].card4.description}
                    fromValue={planFeatureValues[currentPlan].audit}
                    toValue={planFeatureValues[updatedPlan].audit}
                    planTypeChange={planTypeChange}
                />
            </Grid>
        </Grid>
    </>
}
