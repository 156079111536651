import { FC, useEffect } from 'react'
import { UserButton, useUser } from '@clerk/clerk-react'
import { Box, Button, Typography } from '@mui/material'
import { ReactComponent as OnboardingImage } from '../../images/onboarding-image.svg'
import { ReactComponent as NarusLogo } from '../../images/narus-logo.svg'
import { PageView, TrackActionEvent } from '../../service/SegmentService'
import { AccountCreationSideHelp } from './AccountCreationSideHelp'
import { CLERK_ACCOUNT_CREATION_USER_BUTTON_APPEARANCE } from '../../constants/ClerkConstants'
import BusinessIcon from '@mui/icons-material/Business'
import AdminSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import CableIcon from '@mui/icons-material/Cable'
import './AccountCreationOnboarding.scss'

type Props = {
    onComplete: () => void
}

export const AccountCreationOnboarding: FC<Props> = ({
    onComplete
}) => {
    const {user} = useUser()

    const handleStartClicked = () => {
        TrackActionEvent('Create Organization Onboarding', user?.id, {action: 'complete'})
        onComplete()
    }

    useEffect(() => {
        PageView('Create Organization Onboarding')
    }, [])

    return <>
        <Box className='AccountCreation_Container'>
            <Box className='AccountCreation_Content'>
                <Box className='AccountCreation_Side'>
                    <NarusLogo className='AccountCreation_SideLogo'/>
                    <Typography className='AccountCreation_SideTitle'>
                        Welcome to Narus!
                    </Typography>
                    <Typography className='AccountCreation_SideSubtitle'>
                        Creating your company workspace is quick and easy. Completing the following steps can be done in less than five minutes.
                    </Typography>
                    <Box className='AccountCreationOnboarding_List'>
                        <Box className='AccountCreationOnboarding_ListItem'>
                            <BusinessIcon className='AccountCreationOnboarding_ListIcon'/>
                            <Typography className='AccountCreationOnboarding_ListText'>Set up your company space</Typography>
                        </Box>
                        <Box className='AccountCreationOnboarding_ListItem'>
                            <AdminSettingsIcon className='AccountCreationOnboarding_ListIcon'/>
                            <Typography className='AccountCreationOnboarding_ListText'>Assign admin permissions (optional)</Typography>
                        </Box>
                        <Box className='AccountCreationOnboarding_ListItem'>
                            <CableIcon className='AccountCreationOnboarding_ListIcon'/>
                            <Typography className='AccountCreationOnboarding_ListText'>Manage initial access to AI models</Typography>
                        </Box>
                    </Box>
                    <Button className='AccountCreation_SideAction' variant='contained'  size='large' onClick={handleStartClicked}>
                        Let's get started
                    </Button>
                    <AccountCreationSideHelp/>
                </Box>
                <Box className='AccountCreation_Main AccountCreationOnboarding_Content'>
                    <Box className='AccountCreationOnboarding_TitleContainer'>
                        <Typography className='AccountCreationOnboarding_Title'>
                            Your Secure GenAI Platform
                        </Typography>
                        <Typography className='AccountCreationOnboarding_Description'>
                            Keep your business and your budget secure while your team gets to work with Generative AI.
                        </Typography>
                    </Box>
                    <OnboardingImage className='AccountCreation_MainImage'/>
                </Box>
            </Box>
        </Box>
        <Box className='AccountCreation_Avatar'>
            <UserButton appearance={CLERK_ACCOUNT_CREATION_USER_BUTTON_APPEARANCE} showName={false}/>
        </Box>
    </>
}