import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useUser } from '@clerk/clerk-react'
import ErrorIcon from '@mui/icons-material/InsertPageBreak'
import {useStripeSubscriptionsContext} from '../../context/StripeSubscriptionsContext'
import {useUserInfoContext} from '../../context/UserInfoContext'
import {Box, Button, Link, ThemeProvider, Typography} from '@mui/material'
import {RoundIcon} from '../common/roundIcon/RoundIcon'
import {CUSTOM_THEME} from '../../themes/CustomThemes'
import {PageView, TrackActionEvent} from '../../service/SegmentService'
import './LicenseExpiredPage.scss'
import Spinner from '../spinner/Spinner'
import {isAwsSubscription} from '../../utils/subscriptionUtils'
import {AWS_MARKETPLACE_URL, SALES_TEAM_EMAIL, USER_SUPPORT_EMAIL} from '../../constants/Links'

export const LicenseExpiredPage = () => {
	const {user} = useUser()
    const {userInfo} = useUserInfoContext()
    const {isActiveSubscription, currentSubscription, isPausedSubscription, isLoadingSubscriptions} = useStripeSubscriptionsContext()
    const navigate = useNavigate()

    const isAdmin = !!userInfo?.isAdmin
    const isAWSSubscription = isAwsSubscription(currentSubscription)
    
    const title = isPausedSubscription
        ? <>Your trial has ended</>
        : <>It looks like you don’t have a valid license</>

    const trialTextContent = isAdmin
        ? isAWSSubscription
            ? [<>To continue using Narus you must select a paid plan. Your subscription, license, and billing information is all managed through your AWS Marketplace account.</>]
            : [<>To continue enjoying Narus, please explore our plans, <Link href={`mailto:${SALES_TEAM_EMAIL}`}>contact our sales team</Link>, or switch to an account with an active license.</>]
        : [<>To continue enjoying Narus, please contact your Narus admin or switch to an account with an active license.</>]
    
    const licenseTextContent = [<>Want to experience Narus? Explore our plans, <br/><Link href={`mailto:${SALES_TEAM_EMAIL}`}>contact our sales team</Link>, or switch to an account with an active license.</>]

    const textContent = isPausedSubscription
        ? trialTextContent
        : licenseTextContent

    const handleSelectPlanClicked = () => {
		TrackActionEvent('Blocking screen action', user?.externalId ?? user?.id, {action: 'select_plan'})
		navigate('/pricing')
    }

	useEffect(() => {
        if (!isLoadingSubscriptions) {
            if (isActiveSubscription) {
                navigate('/')
            } else {
                PageView('Blocking screen')
            }
        }
	}, [isActiveSubscription, isLoadingSubscriptions, navigate])

    return isLoadingSubscriptions ? <Spinner /> :(
        <ThemeProvider theme={CUSTOM_THEME}>
            <Box className='licenseExpiredPageContainer'>
                <Box className='licenseExpiredPageContent'>
                    <RoundIcon className='licenseExpiredPageIcon' icon={ErrorIcon} size={200} iconSize={100}/>
                    <Box className='licenseExpiredPageText'>
                        <Typography className='licenseExpiredPageTitle'>
                            {title}
                        </Typography>
                        {textContent.map((text, i) => (
                            <Typography key={i} className='licenseExpiredPageDescription'>
                                {text}
                            </Typography>
                        ))}
                    </Box>
                    <Box className='licenseExpiredPageActions'>
                        {isAWSSubscription ? <>
                            <Button variant='outlined' onClick={() => window.location.href = `mailto:${USER_SUPPORT_EMAIL}`}>Contact us</Button>
                            <Button variant='contained' onClick={() => window.open(AWS_MARKETPLACE_URL, '_blank', 'noreferrer')}>Go to AWS Marketplace</Button>
                        </> : <>
                            <Button variant='contained' onClick={handleSelectPlanClicked}>Select a plan</Button>
                        </>}
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    )
}