import {Box, Button, Divider, IconButton, Modal, TextField, Typography} from '@mui/material'
import React, {useState} from 'react'
import './EditSeatsModal.scss'
import {NarusLogo} from '../../icons/NarusLogo'
import CloseIcon from '@mui/icons-material/Close'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import {TrackActionEvent} from '../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {usePaymentContext} from '../../../context/PaymentContext'

type EditSeatsModalProps = {
	seatsModalOpen: boolean
	onModalClose: () => void
}

export const EditSeatsModal = ({seatsModalOpen, onModalClose}: EditSeatsModalProps) => {

	const {seats, updateSeats} = usePaymentContext()

	const {user} = useUser()
	const [updatedSeats, setUpdatedSeats] = useState(seats)

	const decreaseSeatsNumber = () => {
		TrackActionEvent('Edit seats modal', user?.externalId ?? user?.id, {action: 'seats_update_decrease'})
		setUpdatedSeats(previousSeatsNumber => {
			if (!previousSeatsNumber) return 1
			return previousSeatsNumber > 1 ? previousSeatsNumber - 1 : 1
		})
	}

	const increaseSeatsNumber = () => {
		TrackActionEvent('Edit seats modal', user?.externalId ?? user?.id, {action: 'seats_update_increase'})
		setUpdatedSeats(previousSeatsNumber => {
			if (!previousSeatsNumber) return 1
			return previousSeatsNumber + 1
		})
	}

	const onUpdateSeats = () => {
		TrackActionEvent('Edit seats modal', user?.externalId ?? user?.id, {action: 'seats_update_confirm'})
		updateSeats(updatedSeats)
		onModalClose()
	}

	return <Modal open={seatsModalOpen} onClose={onModalClose}>
		<Box className='editSeatsModalContainer'>
			<Box className='modalHeader'>
				<Box className='modelHeaderWrapper'>
					<NarusLogo width={30}/>
					<Box className='modalHeaderTitle'>
						<Typography variant="h6" className='title'>
							Update quantity
						</Typography>
						<Typography variant="h6" className='subtitle'>
							Pro plan billed anually
						</Typography>
					</Box>
				</Box>
				<CloseIcon onClick={onModalClose} className='closeIcon'/>
			</Box>
			<Divider/>

			<Box className='modalBody'>
				<Box className='modalBodyWrapper'>
					<IconButton className='changeSeatsButton' onClick={decreaseSeatsNumber}>
						<RemoveIcon className='subtractIcon'/>
					</IconButton>
					<TextField value={updatedSeats} variant='outlined' className='seatsField'/>
					<IconButton className='changeSeatsButton' onClick={increaseSeatsNumber}>
						<AddIcon className='addIcon'/>
					</IconButton>

				</Box>
				<Button fullWidth variant='contained' className='updateButton' onClick={onUpdateSeats}>Update</Button>
			</Box>
		</Box>
	</Modal>
}