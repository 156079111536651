import {AIModel, AIModelID, ALL_AI_MODEL_IDS, DISABLED_MODEL_IDS, DISABLED_MODEL_IDS_FOR_STATISTICS, OptionalAIModelMap} from '../types/AiModel'

export const isAnyModelSelected = (models: Record<AIModelID, boolean>): boolean =>
    Object.values(models).some(Boolean)

export const getSelectedModelIds = (models: Record<AIModelID, boolean>): AIModelID[] =>
    ALL_AI_MODEL_IDS.filter(id => models[id])

export const getModelId = ({ modelId }: { modelId: AIModelID }): AIModelID =>
    modelId

export const isModelDisabledGlobally = (modelId: AIModelID): boolean =>
    DISABLED_MODEL_IDS.some(id => id === modelId)

export const isModelDisabledGloballyForStatistics = (modelId: AIModelID): boolean =>
    DISABLED_MODEL_IDS_FOR_STATISTICS.some(id => id === modelId)

export const getAIModelIdMap = <T>(value: T) => (models: AIModelID[]): Record<AIModelID, T> =>
    models.reduce((acc, modelId) => ({
        ...acc,
        [modelId]: value
    }), {} as Record<AIModelID, T>)

export const getOptionalAIModelMap = (models: AIModel[]): OptionalAIModelMap =>
    models.reduce<OptionalAIModelMap>((prev, curr) => ({ ...prev, [curr.id]: curr }), {})
