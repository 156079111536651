import {ChangeEvent, useState} from 'react'
import Typography from '@mui/material/Typography'
import {Alert, Box, Button, Drawer, FormControl, Grid, InputLabel, MenuItem, SelectChangeEvent, TextField} from '@mui/material'
import './AiModelsControl.scss'
import {useAiModelsContext} from '../../../../context/AIModelsContext'
import {AIModel, AIModels, OPEN_SOURCE_MODELS} from '../../../../types/AiModel'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {AiModelsControlEdit} from './AiModelsControlEdit'
import {getAiModelsList} from '../../../../helpers/AiModelHelper'
import AddIcon from '@mui/icons-material/Add'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {AiModelControl} from './AiModelControl'
import {SmartPositioningSelect} from '../../../common/SmartPositioningSelect'
import {useStripeSubscriptionsContext} from '../../../../context/StripeSubscriptionsContext'
import {isModelDisabledGlobally} from '../../../../utils/aiModelUtils'

export const AiModelsControl = () => {

	const {user} = useUser()
	const {aiModels, addAiModel} = useAiModelsContext()
	const {displayDrawerEditAiModel, toggleDrawer} = useToggleDrawerContext()
	const {isCurrentSubscriptionGrowth} = useStripeSubscriptionsContext()

	const aiEnabledModels = aiModels?.filter(model => !isModelDisabledGlobally(model.id))

	const [showNewAiModelPanel, setShowNewAiModelPanel] = useState<boolean>(false)
	const [aiModelToConnect, setAiModelToConnect] = useState<string>('')
	const [aiModelApiKey, setAiModelApiKey] = useState<string>('')
	const [aiModelName, setAiModelName] = useState<string>('')
	const [errorApiKey, setErrorApiKey] = useState<boolean>(false)
	const [errorModelName, setErrorModelName] = useState<boolean>(false)
	const [aiModelToEdit, setAiModelToEdit] = useState<AIModel | undefined>(undefined)
	const [errorModelId, setErrorModelId] = useState<boolean>(false)


	const showNoAiModelsEnabledWarning = () => {
		if (!aiEnabledModels.length || aiEnabledModels.some(model => model.isEnabled)) return <></>
		return <Box className='noModelsEnabledWarning'>
			<Alert severity='warning'>All AI models are disabled for all users. Enable one of them to allow them to use Narus</Alert>
		</Box>
	}

	const connectAiModelHandler = async (modelId: string) => {
		if (!aiModelName) setErrorModelName(true)
		if (!aiModelApiKey) setErrorApiKey(true)
		if (!modelId) setErrorModelId(true)

		if (aiModelApiKey && aiModelName && modelId) {
			addAiModel(modelId, aiModelApiKey, aiModelName)
			setShowNewAiModelPanel(false)
			setErrorApiKey(false)
			setErrorModelName(false)
			setErrorModelId(false)
			setAiModelToConnect('')
			TrackActionEvent('AI model control', user?.externalId ?? user?.id, {action: 'connect'})
		}
	}

	const handleNameUpdated = (event: ChangeEvent<HTMLInputElement>) => {
		const newName = event.target.value
		setAiModelName(newName)
		setErrorModelName(!newName.length)
	}

	const handleApiKeyUpdated = (event: ChangeEvent<HTMLInputElement>) => {
		const newApiKey = event.target.value
		setAiModelApiKey(newApiKey)
		setErrorApiKey(!newApiKey.length)
	}

	const handleSelectModelId = (event: SelectChangeEvent<string>) => {
		setAiModelToConnect(event.target.value ?? '')
		setErrorModelId(false)
	}

	const isOpenSourceSelectedModel = aiModelToConnect && OPEN_SOURCE_MODELS.includes(AIModels[aiModelToConnect])

	return <Box className='aiModelsControlContainer'>
		<Typography className='aiModelsControlTitle'>AI models</Typography>
		<Typography className='aiModelsControlSectionSubtitle'>Enable AI models to make them available to your employees.</Typography>
		{showNoAiModelsEnabledWarning()}
		{aiEnabledModels.length ? <Box className='aiModelsTable'>
			{aiEnabledModels.map((aiModel, index) => <AiModelControl aiModel={aiModel} index={index} key={`aiModelControl${index}`}
			                                                         onSelectAiModel={setAiModelToEdit}/>)}
		</Box> : <Alert severity='warning'>You have no AI models available, add one to allow your company to use them.</Alert>}
		{isCurrentSubscriptionGrowth && aiEnabledModels.filter(model => model.isEnabled).length >= 2 ?
				<Alert severity='info' className='maxAiModelsReachedAlert'>You have reached the maximum number of added models for your subscription. To add a new one disable an existing model, or upgrade your subscription.</Alert>
		: <Button startIcon={<AddIcon/>} size='large' className='addAiModelButton' onClick={() => setShowNewAiModelPanel(!showNewAiModelPanel)}>Add AI Model</Button>}
		{showNewAiModelPanel ? <Box className='newAiModelContainer'>
			<Typography variant='h5'>New AI model</Typography>
			<Grid container spacing={'10px'}>
				<Grid item xs={12} md={4}>
					<FormControl fullWidth error={errorModelId}>
						<InputLabel id='ai-model-select-label'>AI Model</InputLabel>
						<SmartPositioningSelect labelId='ai-model-select-label'
						                        id='ai-model-select'
						                        placeholder='Select AI Model'
						                        title='Select AI Model'
						                        label='AI Model'
						                        value={aiModelToConnect}
						                        onChange={handleSelectModelId}
						                        required>
							{getAiModelsList(aiModels).map(({label, value}) => <MenuItem key={value} value={value}>{label}</MenuItem>)}
						</SmartPositioningSelect>
					</FormControl>
				</Grid>
				<Grid item xs={12} md={isOpenSourceSelectedModel ? 8 : 4}>
					<TextField className='textFieldInput'
					           fullWidth
					           required
					           label='Name'
					           variant='outlined'
					           defaultValue={aiModelToConnect ? AIModels[aiModelToConnect].name : ''}
					           error={errorModelName}
					           onChange={handleNameUpdated}/>
				</Grid>
				{!isOpenSourceSelectedModel && <Grid item xs={12} md={4}>
                    <TextField fullWidth
                               required label='API KEY'
                               variant='outlined'
                               className='textFieldInput'
                               error={errorApiKey}
                               onChange={handleApiKeyUpdated}/>
                </Grid>}
			</Grid>
			<Box className='connectAiButtonContainer'>
				<Button className='connectAiButton' variant='contained' size='large' onClick={() => connectAiModelHandler(aiModelToConnect)}>Connect AI</Button>
			</Box>
		</Box> : <></>}
		<Drawer anchor='right' open={displayDrawerEditAiModel}
		        onClose={toggleDrawer(false, 'EDIT_AI_MODEL_DRAWER')}>
			{aiModelToEdit ? <AiModelsControlEdit aiModel={aiModelToEdit}/> : <></>}
		</Drawer>
	</Box>
}