import {useUserContext} from '../context/UserContext'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {UserInfo, UserInfoMap} from '../types/UserInfo'
import {getUsersInfo, updateUsersInfo} from '../service/persistenceService'
import {useFeedbackContext} from '../context/FeedbackContext'
import {UsersAccessContextValue} from '../context/UsersAccessContext'
import {getUserInfoMap} from '../helpers/UserHelpers'

export const useUsersAccess = (): UsersAccessContextValue => {
	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()

	const [loading, setLoading] = useState<boolean>(true)
	const [usersInfo, setUsersInfo] = useState<UserInfo[]>([])
	const [userInfoMap, setUserInfoMap] = useState<UserInfoMap>({})

	const getAllUsersInfo = useCallback(() => {
		setLoading(true)
		getUsersInfo(token).then(usersInfo => {
			setUsersInfo(usersInfo.sort((userInfoA, userInfoB) => userInfoA.userFullName > userInfoB.userFullName ? 1 : -1))
			setLoading(false)
		}).catch(error => {
			showFeedback('Error', error.cause === 403 ?
				'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
				'Something went wrong to load data. Try again reloading the page', 'error')
			setUsersInfo([])
		}).finally(() => setLoading(false))

	}, [token, showFeedback])

	const updateUserInfo = useCallback(async (userInfo: UserInfo) => {
		setLoading(true)
		try {
			await updateUsersInfo(token, userInfo)
			setUsersInfo(previousValues => {
				const userInfoIndex = previousValues.findIndex(previousUserInfo => previousUserInfo.userId === userInfo.userId)
				if (userInfoIndex < 0) return previousValues
				const previousValuesUpdated = [...previousValues]
				previousValuesUpdated[userInfoIndex] = userInfo
				return previousValuesUpdated
			})
			showFeedback('Success', 'User info has been updated', 'success')
			setLoading(false)
		} catch (error: any) {
			showFeedback('Error', error.cause === 403 ?
				'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
				'Something went wrong to update the user data. Try again reloading the page', 'error')
		} finally {
			setLoading(false)
		}
	}, [showFeedback, token])

	useEffect(() => {
		setUserInfoMap(getUserInfoMap(usersInfo))
	}, [usersInfo])

	useEffect(() => {
		getAllUsersInfo()
	}, [getAllUsersInfo])

	const value: UsersAccessContextValue = useMemo(() => ({
		loading,
		usersInfo,
		userInfoMap,
		updateUserInfo
	}), [loading, usersInfo, userInfoMap, updateUserInfo])

	return value
}