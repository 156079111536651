import {FC} from 'react'
import {IconButton, Typography} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

type AuditingLogDetailHeaderProps = {
    onBack: () => void
}

export const AuditingLogDetailHeader: FC<AuditingLogDetailHeaderProps> = ({
    onBack
}) => <>
    <IconButton onClick={onBack} className='auditingLogsGoBackButton'>
        <ArrowBackIcon/>
    </IconButton>
    <Typography variant='h6' className='auditingLogDetailTitle'>Report details</Typography>
</>
