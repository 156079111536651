import {Group} from '../types/Group'
import {UserInfo, UserInfoMap} from '../types/UserInfo'
import {GroupMember} from '../types/GroupMember'

export const getUserGroup = (userInfo: UserInfo, allGroupsMembers: {groupId: string, groupMembers: GroupMember[]}[], groups: Group[]) => {
	const groupId = allGroupsMembers.find(groupMembership => groupMembership.groupMembers.some(groupMember => groupMember.userId === userInfo.userId))?.groupId
	return groups.find(group => group.hashKey === groupId)
}

export const getUserInfoMap = (usersInfo: UserInfo[]): UserInfoMap =>
	usersInfo.reduce<UserInfoMap>((prev, curr) => ({...prev, [curr.userId]: curr}), {})