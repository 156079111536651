import {FC, PropsWithChildren} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import './AppDialog.scss'

export type AppDialogProps = {
    open: boolean
    primaryText: string
    secondaryText: string
    secondaryClassName?: string
    text: string
    title: string
    onClose: () => void
    onPrimary: () => void
    onSecondary?: () => void
}

export const AppDialog: FC<PropsWithChildren<AppDialogProps>> = ({
    open,
    primaryText,
    secondaryText,
    secondaryClassName,
    text,
    title,
    children,
    onClose,
    onPrimary,
    onSecondary
}) => (
    <Dialog onClose={onClose} open={open} className='appDialogContainer' maxWidth='lg'>
        <DialogTitle className='dialogTitle'>
            <span>{title}</span>
            <IconButton onClick={onClose}>
                <CloseIcon/>
            </IconButton>
        </DialogTitle>
        <DialogContent className='dialogContent'>
            <DialogContentText>{text}</DialogContentText>
            {children}
        </DialogContent>
        <DialogActions className='dialogActionsContainer'>
            { onSecondary 
                ? <Button variant='outlined' className={`secondaryButton${secondaryClassName ? ` ${secondaryClassName}` : ''}`} onClick={onSecondary}>{secondaryText}</Button> 
                : <></> }
            <Button variant='contained' className={'primaryButton'} onClick={onPrimary}>{primaryText}</Button>
        </DialogActions>
    </Dialog>
)
