import {useUserContext} from '../context/UserContext'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {getAuditingLogs, markAuditingLogAsViewed} from '../service/persistenceService'
import {useFeedbackContext} from '../context/FeedbackContext'
import {UserAuditLog} from '../types/UserAuditLog'
import {DateRange} from 'rsuite/DateRangePicker'
import {AuditingLogsContextValue} from '../context/AuditingLogsContext'
import {useStripeSubscriptionsContext} from '../context/StripeSubscriptionsContext'
import {updateWithComposition} from '../utils/genericUtils'
import {useAiModelsContext} from '../context/AIModelsContext'
import {useUsersAccessContext} from '../context/UsersAccessContext'
import {AuditInfoStored} from '../types/AuditInfo'
import {isAuditInfoInDateRange, parseAuditLogs} from '../utils/auditInfoUtils'
import {useCursorCallback} from './useCursorCallback'
import {getSubscriptionDateRange} from '../utils/subscriptionUtils'
import {getAllDayDateRange} from '../utils/dateUtils'

export const useAuditingLogs = (): AuditingLogsContextValue => {

	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()
	const {currentSubscription} = useStripeSubscriptionsContext()

	const {aiModelMap} = useAiModelsContext()
	const {userInfoMap} = useUsersAccessContext()
	const [rawLogs, setRawLogs] = useState<AuditInfoStored[]>([])
	const [auditingLogs, setAuditingLogs] = useState<UserAuditLog[]>([])
	const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>()

	const getRawLogs = useMemo(() => {
		const subscriptionDateRange = getSubscriptionDateRange(currentSubscription)
		return getAuditingLogs(token, subscriptionDateRange ? getAllDayDateRange(subscriptionDateRange) : undefined)
	}, [token, currentSubscription])

	const { data, state, error } = useCursorCallback(getRawLogs)

	const viewAuditingLog = useCallback((auditingLog: UserAuditLog) => {
		markAuditingLogAsViewed(token, auditingLog)
			.then(() => {
				const ids = auditingLog.auditLogIds
				setRawLogs(updateWithComposition((log) => ({ ...log, isViewed: true}), (a) => (b) => a.hashKey === b.hashKey && ids.includes(a.hashKey)))
			})
			.catch((error) => {
				showFeedback('Error', error.message ?? 'There was an error updating auditing Log.', 'error')
			})
	}, [token, showFeedback])

	useEffect(() => {
		setRawLogs(error ? [] : data)
	}, [data, error])

	useEffect(() => {
		if (error) {
			showFeedback('Error', error.cause === 403 ?
				'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
				'Something went wrong to load data. Try again reloading the page', 'error')
		}
	}, [error, showFeedback])

	useEffect(() => {
		const filteredLogs = dateRangeFilter ? rawLogs.filter(isAuditInfoInDateRange(getAllDayDateRange(dateRangeFilter))) : rawLogs
		setAuditingLogs(parseAuditLogs(aiModelMap, userInfoMap, filteredLogs).sort((logA: UserAuditLog, logB: UserAuditLog) => logB.conversationDate - logA.conversationDate))
	}, [aiModelMap, userInfoMap, rawLogs, dateRangeFilter])

	return useMemo(() => ({
		loading: state !== 'completed',
		auditingLogs,
		rawLogs,
		dateRangeFilter,
		setDateRangeFilter,
		viewAuditingLog
	}), [state, auditingLogs, rawLogs, dateRangeFilter, viewAuditingLog])
}