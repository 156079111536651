import { FC } from 'react'
import { ACCOUNT_CREATION_CONFIG } from '../../constants/AccountCreationConstants'
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { StepIndex, StepIndexes } from '../../types/AccountCreation'
import { ReactComponent as NarusLogo } from '../../images/narus-logo.svg'
import { AccountCreationSideHelp } from './AccountCreationSideHelp'
import CheckIcon from '@mui/icons-material/CheckRounded'
import './AccountCreationAside.scss'

type AccountCreationAsideProps = {
    index: StepIndex
    completed: Record<StepIndex, boolean>
}

export const AccountCreationAside: FC<AccountCreationAsideProps> = ({
    index,
    completed
}) => {
    return (
        <Box className='AccountCreation_Side'>
            <NarusLogo className='AccountCreation_SideLogo'/>
            <Typography className='AccountCreation_SideTitle'>Set up your company</Typography>
            <Box className='AccountCreation_SideStepper'>
                <Stepper activeStep={index} orientation='vertical'>
                    { StepIndexes.map(value => <Step key={value} completed={completed[value]}>
                        { completed[value]
                                ? <StepLabel icon={<CheckIcon/>}>{ACCOUNT_CREATION_CONFIG[value].title}</StepLabel>
                                : <StepLabel>{ACCOUNT_CREATION_CONFIG[value].title}</StepLabel> }
                    </Step>)}
                </Stepper>
            </Box>
            <AccountCreationSideHelp/>
        </Box>
    )
}