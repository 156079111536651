import {Grid, Link, Stack} from '@mui/material'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import {TrackActionEvent} from '../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import './BillingTabs.scss'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import {InternalNavigationMap} from '../../../types/AppNavigation'
import {useAppNavigationContext} from '../../../context/AppNavigationContext'
import {useStripeSubscriptionsContext} from '../../../context/StripeSubscriptionsContext'
import {isAwsSubscription} from '../../../utils/subscriptionUtils'

export const BillingTabs = () => {

    const {internalNavigation, internalNavigate} = useAppNavigationContext()
    const {user} = useUser()
    const {currentSubscription} = useStripeSubscriptionsContext()
    const isAWSSubscription = isAwsSubscription(currentSubscription)
    
    const billingTabView: InternalNavigationMap['licenseSettings'] = internalNavigation['licenseSettings'] ?? 'subscription'

    const onTabChangeClick = (currentTab: InternalNavigationMap['licenseSettings']) => {
        internalNavigate({licenseSettings: currentTab})
        TrackActionEvent('Organization settings', user?.externalId ?? user?.id, {
            action: 'changeSubtab',
            tab: currentTab
        })
    }

    return <Grid item xs='auto' className='billingTabsContainer'>
        <Stack spacing={3}>
            <Link className={`${billingTabView === 'subscription' ? 'active' : ''} licenseSubmenu`} onClick={() => onTabChangeClick('subscription')}>
                <AccountBalanceWalletIcon fontSize="small"/>
                Current Subscription
            </Link>
            {!isAWSSubscription && <>
                <Link className={`${billingTabView === 'payment' ? 'active' : ''} licenseSubmenu`} onClick={() => onTabChangeClick('payment')}>
                    <CreditCardOutlinedIcon fontSize="small"/>
                    Payment Details
                </Link>
                <Link className={`${billingTabView === 'invoice' ? 'active' : ''} licenseSubmenu`} onClick={() => onTabChangeClick('invoice')}>
                    <RequestQuoteIcon fontSize="small"/>
                    Invoice history
                </Link>
            </>}
        </Stack>
    </Grid>
}