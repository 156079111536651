import React, {useCallback, useMemo, useState} from 'react'
import {useUser} from '@clerk/clerk-react'
import {useUserContext} from '../context/UserContext'
import {usePromptCreationContext} from '../context/PromptCreationContext'
import {getHomeTabForAnalytics, TrackActionEvent} from '../service/SegmentService'
import {ToggleDrawerContextValue} from '../context/ToggleDrawerContext'


export const DrawerTypes = [
	'PROMPT_SETTINGS_DRAWER',
	'LABELS_DRAWER',
	'PROMPT_TITLE_DRAWER',
	'PRE_CREATE_PROMPT_DRAWER',
	'SAVE_CHAT_COMPILATION_DRAWER',
	'SAVE_CHAT_HISTORY_DRAWER',
	'EDIT_USER_DRAWER',
	'EDIT_AI_MODEL_DRAWER',
	'EDIT_ORGANIZATION_DOMAIN_DRAWER',
	'EDIT_FORMAT_WARNING_DRAWER',
	'EDIT_TOPIC_WARNING_DRAWER',
	'EDIT_WORD_WARNING_DRAWER',
	'CREATE_GROUP_DRAWER',
	'EDIT_GROUP_AI_MODELS_DRAWER',
	'SHOW_GROUP_AI_MODEL_ACCESS',
	'EDIT_OVERALL_BUDGET'
] as const

export type DrawerType = typeof DrawerTypes[number]

export const useToggleDrawer = (): ToggleDrawerContextValue => {

	const [displayPrePromptCreationDrawer, setDisplayPrePromptCreationDrawer] = useState<boolean>(false)
	const [displayDrawerPromptSettings, setDisplayDrawerPromptSettings] = useState<boolean>(false)
	const [displayDrawerLabels, setDisplayDrawerLabels] = useState<boolean>(false)
	const [displayDrawerTitle, setDisplayDrawerTitle] = useState<boolean>(false)
	const [displayDrawerSaveChatCompilation, setDisplayDrawerSaveChatCompilation] = useState<boolean>(false)
	const [displayDrawerEditUser, setDisplayDrawerEditUser] = useState<boolean>(false)
	const [displayDrawerEditAiModel, setDisplayDrawerEditAiModel] = useState<boolean>(false)
	const [displayDrawerEditOrganizationDomain, setDisplayDrawerEditOrganizationDomain] = useState<boolean>(false)
	const [displayDrawerEditFormatWarning, setDisplayDrawerEditFormatWarning] = useState<boolean>(false)
	const [displayDrawerEditTopicWarning, setDisplayDrawerEditTopicWarning] = useState<boolean>(false)
	const [displayDrawerEditWordWarning, setDisplayDrawerEditWordWarning] = useState<boolean>(false)
	const [displayDrawerSaveChatHistory, setDisplayDrawerSaveChatHistory] = useState<boolean>(false)
	const [displayDrawerCreateGroup, setDisplayDrawerCreateGroup] = useState<boolean>(false)
	const [displayDrawerEditGroupAiModels, setDisplayDrawerEditGroupAiModels] = useState<boolean>(false)
	const [displayDrawerShowGroupAiModelAccess, setDisplayDrawerShowGroupAiModelAccess] = useState<boolean>(false)
	const [displayDrawerEditOverallBudget, setDisplayDrawerEditOverallBudget] = useState<boolean>(false)


	const {user} = useUser()
	const {selectedTab} = useUserContext()
	const {modelId, tone, audience, format} = usePromptCreationContext()

	const handleCloseSaveChatDrawer = useCallback((eventName: string, id?: string) => {
		const analyticsExtraInfo = {action: 'done'}
		if (id) analyticsExtraInfo[eventName === 'Save chat compilation' ? 'prompt_id' : 'chat_id'] = id
		TrackActionEvent(eventName, user?.externalId ?? user?.id, analyticsExtraInfo)
		setDisplayDrawerSaveChatCompilation(false)
		setDisplayDrawerSaveChatHistory(false)
	}, [user?.id, user?.externalId])

	const toggleDrawer = useCallback((open: boolean, drawerType?: string, promptId?: string) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
			return
		}

		const analyticsExtraInfo = {action: open ? 'edit' : 'done'}
		if (promptId) analyticsExtraInfo['prompt_id'] = promptId
		switch (drawerType) {
			case 'PROMPT_SETTINGS_DRAWER':
				TrackActionEvent('Advanced settings', user?.externalId ?? user?.id, analyticsExtraInfo)
				setDisplayDrawerPromptSettings(open)
				break
			case 'LABELS_DRAWER':
				TrackActionEvent('Labels & teams', user?.externalId ?? user?.id, analyticsExtraInfo)
				setDisplayDrawerLabels(open)
				break
			case 'PROMPT_TITLE_DRAWER':
				TrackActionEvent('Title & description', user?.externalId ?? user?.id, analyticsExtraInfo)
				setDisplayDrawerTitle(open)
				break
			case 'SAVE_CHAT_COMPILATION_DRAWER':
				TrackActionEvent('Save chat compilation', user?.externalId ?? user?.id, analyticsExtraInfo)
				setDisplayDrawerSaveChatCompilation(open)
				break
			case 'SAVE_CHAT_HISTORY_DRAWER':
				TrackActionEvent('Save chat conversation', user?.externalId ?? user?.id, analyticsExtraInfo)
				setDisplayDrawerSaveChatHistory(open)
				break
			case 'EDIT_USER_DRAWER':
				TrackActionEvent('Edit user', user?.externalId ?? user?.id, analyticsExtraInfo)
				setDisplayDrawerEditUser(open)
				break
			case 'EDIT_AI_MODEL_DRAWER':
				TrackActionEvent('Edit AI model info', user?.externalId ?? user?.id, analyticsExtraInfo)
				setDisplayDrawerEditAiModel(open)
				break
			case 'EDIT_ORGANIZATION_DOMAIN_DRAWER':
				TrackActionEvent('Edit organization', user?.externalId ?? user?.id, analyticsExtraInfo)
				setDisplayDrawerEditOrganizationDomain(open)
				break
			case 'EDIT_TOPIC_WARNING_DRAWER':
				TrackActionEvent('Warnings', user?.externalId ?? user?.id, {...analyticsExtraInfo, type: 'topic'})
				setDisplayDrawerEditTopicWarning(open)
				break
			case 'EDIT_WORD_WARNING_DRAWER':
				TrackActionEvent('Warnings', user?.externalId ?? user?.id, {...analyticsExtraInfo, type: 'word'})
				setDisplayDrawerEditWordWarning(open)
				break
			case 'EDIT_FORMAT_WARNING_DRAWER':
				TrackActionEvent('Warnings', user?.externalId ?? user?.id, {...analyticsExtraInfo, type: 'format'})
				setDisplayDrawerEditFormatWarning(open)
				break
			case 'CREATE_GROUP_DRAWER':
				TrackActionEvent('Create group', user?.externalId ?? user?.id, analyticsExtraInfo)
				setDisplayDrawerCreateGroup(open)
				break
			case 'EDIT_GROUP_AI_MODELS_DRAWER':
				TrackActionEvent('Edit group AI models', user?.externalId ?? user?.id, analyticsExtraInfo)
				setDisplayDrawerEditGroupAiModels(open)
				break
			case 'SHOW_GROUP_AI_MODEL_ACCESS':
				TrackActionEvent('Show group AI model access', user?.externalId ?? user?.id, analyticsExtraInfo)
				setDisplayDrawerShowGroupAiModelAccess(open)
				break
			case 'EDIT_OVERALL_BUDGET':
				TrackActionEvent('Budget', user?.externalId ?? user?.id, {...analyticsExtraInfo, type: 'overall'})
				setDisplayDrawerEditOverallBudget(open)
				break
			case 'PRE_CREATE_PROMPT_DRAWER':
				const createPromptAnalyticsInfo = {
					origin_tab: getHomeTabForAnalytics(selectedTab),
					model: modelId,
					action: open ? 'show' : 'close'
				}

				if (tone) createPromptAnalyticsInfo['tone'] = tone
				if (audience) createPromptAnalyticsInfo['audience'] = audience
				if (format) createPromptAnalyticsInfo['format'] = format
				TrackActionEvent('Create prompt', user?.externalId ?? user?.id, createPromptAnalyticsInfo)
				setDisplayPrePromptCreationDrawer(open)
				break
			default:
				break
		}
	}, [user?.id, user?.externalId, tone, audience, format, selectedTab, modelId])

	return useMemo(() => ({
		displayPrePromptCreationDrawer,
		displayDrawerLabels,
		displayDrawerTitle,
		displayDrawerPromptSettings,
		displayDrawerEditUser,
		displayDrawerEditAiModel,
		displayDrawerEditOrganizationDomain,
		displayDrawerEditTopicWarning,
		displayDrawerEditWordWarning,
		displayDrawerEditFormatWarning,
		setDisplayPrePromptCreationDrawer,
		displayDrawerSaveChatCompilation,
		displayDrawerSaveChatHistory,
		displayDrawerCreateGroup,
		displayDrawerEditGroupAiModels,
		displayDrawerShowGroupAiModelAccess,
		displayDrawerEditOverallBudget,
		toggleDrawer,
		handleCloseSaveChatDrawer
	}), [displayPrePromptCreationDrawer,
		displayDrawerLabels,
		displayDrawerTitle,
		displayDrawerPromptSettings,
		displayDrawerEditUser,
		displayDrawerEditAiModel,
		displayDrawerEditOrganizationDomain,
		displayDrawerEditTopicWarning,
		displayDrawerEditWordWarning,
		displayDrawerEditFormatWarning,
		setDisplayPrePromptCreationDrawer,
		displayDrawerSaveChatCompilation,
		displayDrawerSaveChatHistory,
		displayDrawerCreateGroup,
		displayDrawerEditGroupAiModels,
		displayDrawerShowGroupAiModelAccess,
		displayDrawerEditOverallBudget,
		toggleDrawer,
		handleCloseSaveChatDrawer])
}