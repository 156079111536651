import {createContext, FC, PropsWithChildren, useContext} from 'react'
import {UserInfo, UserInfoMap} from '../types/UserInfo'
import {useUsersAccess} from '../hooks/useUsersAccess'

export interface UsersAccessContextValue {
	loading: boolean
	usersInfo: UserInfo[]
	userInfoMap: UserInfoMap
	updateUserInfo: (userInfo: UserInfo) => Promise<void>
}

const DEFAULT_USER_ACCESS_CONTEXT: UsersAccessContextValue = {
	loading: false,
	usersInfo: [],
	userInfoMap: {},
	updateUserInfo: () => Promise.resolve()
}

export const UsersAccessContext = createContext<UsersAccessContextValue>(DEFAULT_USER_ACCESS_CONTEXT)

export const useUsersAccessContext = () => useContext(UsersAccessContext)

export const UsersAccessContextProvider: FC<PropsWithChildren> = ({children}) => {

	const value = useUsersAccess()

	return <UsersAccessContext.Provider value={value}>
		{children}
	</UsersAccessContext.Provider>
}