import { useCallback, useEffect, useState } from 'react'
import { AsyncState } from '../types/AsyncState'

export const useCursorCallback = <DataType, CursorType>(callback: (cursor: CursorType | undefined, signal: AbortSignal) => Promise<{data?: DataType[], key?: CursorType}>) => {
    const [state, setState] = useState<AsyncState>('idle')
    const [data, setData] = useState<DataType[]>([])
    const [cursor, setCursor] = useState<CursorType>()
    const [error, setError] = useState<any>()

    const retry = useCallback(() => {
        setState('idle')
        setData([])
        setCursor(undefined)
        setError(undefined)
    }, [])

    /** When callback changes, reset hook data */
    useEffect(() => {
        retry()
    }, [callback, retry])

    useEffect(() => {
        const controller: AbortController = new AbortController()
        setState('loading')
        callback(cursor, controller.signal)
            .then((response) => {
                const newData = response.data
                const newKey = response.key
                if (newData) {
                    setData(prev => [...prev, ...newData])
                }
                if (newKey) {
                    setCursor(newKey)
                } else {
                    setState('completed')
                }
            })
            .catch(error => {
                if (error?.message !== 'canceled') {
                    setState('error')
                    setError(error)
                }
            })

        return () => {
            controller.abort?.()
        }
    }, [callback, cursor])

    return {
        state,
        data,
        error,
        retry
    }

}