import { FC, PropsWithChildren, createContext, useCallback, useContext, useState } from 'react'
import { DEFAULT_ACCOUNT_CREATION_CONTEXT_VALUE } from '../constants/AccountCreationConstants'
import { useAccountCreation } from '../hooks/useAccountCreation'
import { ContextValue } from '../types/AccountCreation'
import { AccountCreationOnboarding } from '../components/accountCreation/AccountCreationOnboarding'

const AccountCreationContext = createContext<ContextValue>(DEFAULT_ACCOUNT_CREATION_CONTEXT_VALUE)

export const useAccountCreationContext = () => useContext(AccountCreationContext)

export const AccountCreationContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [onboarded, setOnboarded] = useState(false)
    const value = useAccountCreation()

    const handleOnboardingComplete = useCallback(() => {
        setOnboarded(true)
    }, [])

    return <AccountCreationContext.Provider value={value}>
        { onboarded ?  children : <AccountCreationOnboarding onComplete={handleOnboardingComplete}/> }
    </AccountCreationContext.Provider>
}