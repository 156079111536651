import {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {Group} from '../types/Group'
import {getGroupByUserId} from '../service/persistenceService'
import {useUserContext} from './UserContext'
import {useStripeSubscriptionsContext} from './StripeSubscriptionsContext'

type UserGroupContextValue = {
	userGroup?: Group
}

const DEFAULT_USER_GROUP_CONTEXT: UserGroupContextValue = {
	userGroup: undefined
}

export const UserGroupContext = createContext<UserGroupContextValue>(DEFAULT_USER_GROUP_CONTEXT)

export const useUserGroupContext = () => useContext(UserGroupContext)

export const UserGroupContextProvider: FC<PropsWithChildren> = ({children}) => {
	const {token} = useUserContext()
	const { isCurrentSubscriptionGrowth } = useStripeSubscriptionsContext()

	const [userGroup, setUserGroup] = useState<Group>()

	const value: UserGroupContextValue = useMemo(() => ({
		userGroup,
	}), [userGroup])

	const getUserGroup = useCallback(async () => {
		const userGroup = await getGroupByUserId(token)
		setUserGroup(userGroup)
	}, [token])

	useEffect(() => {
		if (!isCurrentSubscriptionGrowth) getUserGroup()
	}, [isCurrentSubscriptionGrowth, getUserGroup])

	return <UserGroupContext.Provider value={value}>
		{children}
	</UserGroupContext.Provider>
}