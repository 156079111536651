import {StripeSubscriptionsContextProvider} from '../../context/StripeSubscriptionsContext'
import {UserContextProvider} from '../../context/UserContext'
import {UserInfoContextProvider} from '../../context/UserInfoContext'
import {UserOrganizationContextProvider} from '../../context/UserOrganizationContext'
import {LicenseExpiredPage} from './LicenseExpiredPage'

export const LicenseExpiredWrapper = () => (
    <UserContextProvider>
        <UserOrganizationContextProvider>
            <UserInfoContextProvider>
                <StripeSubscriptionsContextProvider>
                    <LicenseExpiredPage/>
                </StripeSubscriptionsContextProvider>
            </UserInfoContextProvider>
        </UserOrganizationContextProvider>
    </UserContextProvider>
)