import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useUser} from '@clerk/clerk-react'
import {Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography,} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import {useFeedbackContext} from '../../context/FeedbackContext'
import {useMarketingCommsContext} from '../../context/MarketingCommsContext'
import {ReactComponent as GetStartedImage} from '../../images/get-started-image.svg'
import {ReactComponent as NarusLogoIcon} from '../../images/narus-logo.svg'
import './GetStarted.scss'
import Spinner from '../spinner/Spinner'
import {useUserInfoContext} from '../../context/UserInfoContext'
import {PageView, TrackActionEvent} from '../../service/SegmentService'
import {END_USER_DOCUMENTATION_URL} from '../../constants/Links'

export const GetStarted = () => {
	const {user} = useUser()
	const {userInfo, updateUserInformation} = useUserInfoContext()
	const {showFeedback} = useFeedbackContext()
	const {trackMarketingEvent} = useMarketingCommsContext()
	const navigate = useNavigate()

	const [firstName, setFirstName] = useState<string>(user?.firstName ?? '')
	const [lastName, setLastName] = useState<string>(user?.lastName ?? '')
	const [loading, setLoading] = useState(false)
	const [isMarketingChecked, setIsMarketingChecked] = useState(false)

	const handleUpdateUser = async () => {
		if (userInfo && user) {
			if (!firstName.length || !lastName.length) {
				showFeedback('Error', 'First name and last name are mandatory', 'error')
				return
			}
			setLoading(true)
			TrackActionEvent('Getting start', user?.id, {action: 'continue'})
			updateUserInformation({...userInfo, userFullName: `${firstName} ${lastName}`})
			await Promise.all([
				trackMarketingEvent(isMarketingChecked),
			    user.update({firstName, lastName})
			])
			await user.reload()
			navigate('/')
		}
	}

	const handleSkipUpdate = () => {
		if (userInfo) {
			TrackActionEvent('Getting start', user?.id, {action: 'skip'})
			updateUserInformation(userInfo).then(() => navigate('/'))
		}
	}

	const handleGetHelp = () => {
		TrackActionEvent('Getting start', user?.id, {action: 'help'})
		window.open(END_USER_DOCUMENTATION_URL, '_blank', 'noreferrer')
	}

	useEffect(() => {
		PageView('Getting started')
		if (userInfo?.isOnboarded) navigate('/')
	}, [userInfo, navigate])



	return (loading ? <Spinner/> :
			<Grid container className='getStartedContainer'>
				<Grid item xs={6} className='leftContainer'>
					<Box className='contentWrapper'>
						<Box>
							<NarusLogoIcon/>
						</Box>
						<Box className='detailsContainer'>
							<Typography variant='h3' className='title'>Welcome to Narus</Typography>
							<Typography variant='h6' className='subtitle'>
								Let us know, and we’ll help personalize your getting started experience
							</Typography>
							<Box className='field'>
								<Typography className='label-firstname'>First name</Typography>
								<TextField fullWidth variant='outlined' required defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)} size='small'/>
								<Typography className='label-lastname'>Last name</Typography>
								<TextField fullWidth variant='outlined' required defaultValue={lastName} onChange={(e) => setLastName(e.target.value)} size='small'/>
								<FormControlLabel
									className='checkboxWrapper'
									control={<Checkbox className='checkbox'
									                   checked={isMarketingChecked}
									                   onChange={(e) => setIsMarketingChecked(e.target.checked)}/>}
									label={
										<Typography className='checkboxLabel'>
											I would like to receive email communications with updates and promotions about Narus
										</Typography>
									}
								/>
							</Box>
						</Box>
						<Box className='buttonsContainer'>
							<Button size='large' variant='contained' className='continueButton' onClick={handleUpdateUser}>
								Continue
							</Button>
							<Button variant='text' className='skipButton' onClick={handleSkipUpdate}>
								Skip
							</Button>
						</Box>
						<Box className='helpContainer' onClick={handleGetHelp}>
							<HelpIcon className='helpIcon'/>
							<Typography className='helpText'>Need help with anything?</Typography>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={6} className='rightContainer'>
					<Box className='contentWrapper'>
						<Typography variant='h4' className='rightTitle'>
							Your new private and secure AI chat
							portal</Typography>
						<GetStartedImage className='image'/>
					</Box>
				</Grid>
			</Grid>
	)
}