import {createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext} from 'react'
import {UserAuditLog} from '../types/UserAuditLog'
import {useAuditingLogs} from '../hooks/useAuditingLogs'
import {DateRange} from 'rsuite/DateRangePicker'
import { AuditInfoStored } from '../types/AuditInfo'

export type AuditingLogsContextValue = {
	loading: boolean
	auditingLogs: UserAuditLog[]
	rawLogs: AuditInfoStored[]
	dateRangeFilter: DateRange | undefined
	setDateRangeFilter: Dispatch<SetStateAction<DateRange | undefined>>
	viewAuditingLog: (auditLog: UserAuditLog) => void
}

const DEFAULT_AUDITING_LOGS_CONTEXT: AuditingLogsContextValue = {
	loading: false,
	auditingLogs: [],
	rawLogs: [],
	dateRangeFilter: undefined,
	setDateRangeFilter: () => {},
	viewAuditingLog: () => {}
}

const AuditingLogsContext = createContext<AuditingLogsContextValue>(DEFAULT_AUDITING_LOGS_CONTEXT)

export const useAuditingLogsContext = () => useContext(AuditingLogsContext)

export const AuditingLogsContextProvider: FC<PropsWithChildren> = ({
	children
}) => {
	const value = useAuditingLogs()

	return <AuditingLogsContext.Provider value={value}>
		{children}
	</AuditingLogsContext.Provider>
}