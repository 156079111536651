import {DateRangePicker} from 'rsuite'
import React from 'react'
import 'rsuite/dist/rsuite-no-reset.min.css'
import subDays from 'date-fns/subDays'
import addMonths from 'date-fns/addMonths'
import differenceInDays from 'date-fns/differenceInDays'
import {DateRange} from 'rsuite/DateRangePicker'
import EventIcon from '@mui/icons-material/Event'
import {useStatisticsContext} from '../../context/StatisticsContext'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {RangeType} from 'rsuite/DatePicker'
import {useAuditingLogsContext} from '../../context/AuditingLogsContext'
import {convertToStartOfDay} from '../../helpers/DateHelpers'

type CustomDateRangePickerProps = {
	currentTab: 'OVERVIEW' | 'REPORTS'
	maxBackDate?: Date
}

export const CustomDateRangePicker = ({currentTab, maxBackDate}: CustomDateRangePickerProps) => {

	const {user} = useUser()
	const {dateRangeFilter: statisticsDateRangeFilter, setDateRangeFilter: setStatisticsDateRangeFilter} = useStatisticsContext()
	const {dateRangeFilter: auditDateRangeFilter, setDateRangeFilter: setAuditingLogsDateRangeFilter} = useAuditingLogsContext()

	const eventName: string = currentTab === 'OVERVIEW' ? 'Overview calendar filter' : 'Reports calendar filter'
	const dateRangeFilter = currentTab === 'OVERVIEW' ? statisticsDateRangeFilter : auditDateRangeFilter

	const predefinedRanges = [
		{
			label: 'Last week',
			value: [subDays(new Date(), 7), subDays(new Date(), 7)] as DateRange,
		},
		{
			label: 'Last month',
			value: [addMonths(new Date(), -1), addMonths(new Date(), -1)] as DateRange,
		},
		{
			label: 'Last 3 months',
			value: [addMonths(new Date(), -3), addMonths(new Date(), -1)] as DateRange,
		},
	]

	const onDateRangeHandler = (dateRange: DateRange | null, event: React.SyntheticEvent) => {
		if(!event.currentTarget.getAttribute('aria-invalid')) {
			TrackActionEvent(eventName, user?.externalId ?? user?.id, {action: 'select', total_days_selected: dateRange ? differenceInDays(dateRange[1], dateRange[0]) + 1 : 0})
			currentTab === 'OVERVIEW' ? setStatisticsDateRangeFilter(dateRange ?? undefined) : setAuditingLogsDateRangeFilter(dateRange ?? undefined)
		}
	}

	const onOpenCalendarHandler = () => {
		TrackActionEvent(eventName, user?.externalId ?? user?.id, {action: 'open'})
	}

	const onShortcutClickHandler = (shortcut: RangeType<DateRange>, event: any) => {
		switch(shortcut.label) {
			case 'Last week':
				TrackActionEvent(eventName, user?.externalId ?? user?.id, {action: 'last_week', total_days_selected: 7})
				break
			case 'Last month':
				TrackActionEvent(eventName, user?.externalId ?? user?.id, {action: 'last_month', total_days_selected: differenceInDays(shortcut.value[1], shortcut.value[0]) + 1})
				break
			default:
				TrackActionEvent(eventName, user?.externalId ?? user?.id, {action: 'last_3_months', total_days_selected: differenceInDays(shortcut.value[1], shortcut.value[0]) + 1})
		}
	}

	const onCleanHandler = () => {
		TrackActionEvent(eventName, user?.externalId ?? user?.id, {action: 'clean'})
	}

	return <DateRangePicker
		value={dateRangeFilter}
		ranges={predefinedRanges}
		placeholder="YYYY/MM/DD - YYYY/MM/DD"
		caretAs={EventIcon}
		character=' - '
		showHeader={false}
		format='yyyy/MM/dd'
		shouldDisableDate={(date: Date) => date > new Date() || (maxBackDate !== undefined && date <= convertToStartOfDay(maxBackDate))}
		onChange={onDateRangeHandler}
		onOpen={onOpenCalendarHandler}
		onShortcutClick={onShortcutClickHandler}
		onClean={onCleanHandler}
		placement='bottomEnd'
	/>
}