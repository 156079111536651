import {Box, Button, Typography} from '@mui/material'
import {RoundIcon} from '../components/common/roundIcon/RoundIcon'
import ErrorIcon from '@mui/icons-material/Error'
import './SignUpError.scss'
import {useClerk} from '@clerk/clerk-react'

export const SignUpError = () => {

    const {signOut} = useClerk()

    return <Box className='signUpErrorContainer'>
        <RoundIcon className='signUpErrorIcon' icon={ErrorIcon} size={200} iconSize={150} colorEnd='#CA97A280'
                   colorStart='#C1AFE580' iconColorEnd='#C1AFE580' iconColorStart='#CA97A280'/>
        <Typography className='signUpErrorTitle'>Oops! Signing up went wrong</Typography>
        <Typography className='signUpErrorSubtitle'>Generic emails like @gmail.com are not allowed. Please use a
            business email account to access</Typography>
        <Button className='signUpErrorButton' variant='contained'
                onClick={() => signOut({redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}/trial`})}>Back to sign up</Button>
    </Box>
}