import React from 'react'
import {Outlet} from 'react-router-dom'
import {UserContextProvider} from '../../context/UserContext'
import {MarketingCommsContextProvider} from '../../context/MarketingCommsContext'
import { UserInfoContextProvider } from '../../context/UserInfoContext'
import {FeedbackMessage} from '../feedbackMessage/FeedbackMessage'

export const GetStartedWrapper = () => (
    <UserContextProvider>
        <MarketingCommsContextProvider>
            <UserInfoContextProvider>
                <Outlet/>
                <FeedbackMessage/>
            </UserInfoContextProvider>
        </MarketingCommsContextProvider>
    </UserContextProvider>
)
