import {Box, Button, Drawer, Grid, Tooltip, Typography, Tabs, Tab} from '@mui/material'
import {useState} from 'react'
import './BudgetControl.scss'
import {useGroupsContext} from '../../../../context/GroupsContext'
import {EmptyGroups} from '../../../emptySection/EmptyGroups'
import InfoIcon from '@mui/icons-material/Info'
import {GroupCreation} from '../groups/GroupCreation'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {OverallBudget} from './OverallBudget'
import {useBudgetsContext} from '../../../../context/BudgetContext'
import {formatDateStringToLocaleDate} from '../../../../helpers/DateHelpers'
import ConfirmationDialog from '../../../promptForm/confirmationDialog/ConfirmationDialog'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {formatAmount} from '../../../../utils/currencyUtils'
import {CustomTabPanel} from '../../../homeTabs/HomeTabs'

export const BudgetControl = () => {

	const {user} = useUser()
	const {groups} = useGroupsContext()
	const {toggleDrawer, displayDrawerCreateGroup, displayDrawerEditOverallBudget} = useToggleDrawerContext()
	const {overallBudget, removeBudget} = useBudgetsContext()
	const [selectedTab, setSelectedTab] = useState<number>(0)
	const [showBudgetDeletionDialog, setShowBudgetDeletionDialog] = useState<boolean>(false)

	const handleTabChange = (_: React.SyntheticEvent, tabIndex: number) => {
		const sectionNames = ['Settings', 'Limits History']
		setSelectedTab(tabIndex)
		TrackActionEvent('Spending Limits tab', user?.externalId ?? user?.id, {section: sectionNames[tabIndex]})
	}
	const deleteOverallBudgetHandler = () => {
		TrackActionEvent('Budget', user?.externalId ?? user?.id, {action: 'remove', type: 'overall'})
		setShowBudgetDeletionDialog(false)
		if (overallBudget) removeBudget(overallBudget)
	}

	return (
		<Box className='budgetControlContainer'>
			<Typography className='title'>Spending Limits</Typography>
			<Typography className='subtitle'>Set spending limits for your entire organisation and individual teams.</Typography>

			<Box className='tabsContainer'>
				<Tabs value={selectedTab} onChange={handleTabChange}>
					<Tab label='Settings'/>
					<Tab label='Limits History'/>
				</Tabs>
			</Box>
			<CustomTabPanel value={selectedTab} index={0}>
				<Grid item xs={12}>
					<Box className='organizationBudgetContainer'>
						<Box className='budgetLimitContainer'>
							<Typography className='title'>Organisation limit</Typography>
							<Typography className='subtitle'>
								{overallBudget ? formatAmount(overallBudget.amount) : <>
									Unlimited <Tooltip title='If the overall budget for the workspace is not defined, it will be unlimited for employees with access to AI in Narus'
																		 placement='top'>
									<InfoIcon/>
								</Tooltip></>}</Typography>
							{overallBudget ? <Typography className='renewedBudget'>Budget renewed: {formatDateStringToLocaleDate(overallBudget.periodStartDate)} </Typography> : <></>}
						</Box>
						<Box className='buttonsRow'>
							<Button variant='contained' onClick={toggleDrawer(true, 'EDIT_OVERALL_BUDGET')}>Set organisation limit</Button>
						</Box>
					</Box>
				</Grid>

				<Grid item xs={12} className='budgetControlNoTeams'>
					{!groups.length ? <EmptyGroups>
						<Box className='noTeamsButtonContainer'>
							<Button variant='outlined' onClick={toggleDrawer(true, 'CREATE_GROUP_DRAWER')}>Create a new team</Button>
						</Box>
					</EmptyGroups> : <></>}
				</Grid>
				<Drawer anchor='right' open={displayDrawerCreateGroup} onClose={toggleDrawer(false, 'CREATE_GROUP_DRAWER')}>
					<GroupCreation/>
				</Drawer>

				<Drawer anchor='right' open={displayDrawerEditOverallBudget} onClose={toggleDrawer(false, 'EDIT_OVERALL_BUDGET')}>
					<OverallBudget/>
				</Drawer>
				<ConfirmationDialog handleClose={() => setShowBudgetDeletionDialog(false)} open={showBudgetDeletionDialog}
														currentEvent='overallBudgetDeletion' handleDelete={deleteOverallBudgetHandler}/>
			</CustomTabPanel>
			<CustomTabPanel value={selectedTab} index={1}>
			</CustomTabPanel>
		</Box>
	)
}