import {useState} from 'react'
import {PaymentElement, useElements} from '@stripe/react-stripe-js'
import {Button, Typography} from '@mui/material'
import Spinner from '../../spinner/Spinner'
import {useFeedbackContext} from '../../../context/FeedbackContext'
import {Stripe} from '@stripe/stripe-js'
import {updateSubscription} from '../../../service/persistenceService'
import {usePaymentContext} from '../../../context/PaymentContext'
import {useUserContext} from '../../../context/UserContext'
import {useStripeSubscriptionsContext} from '../../../context/StripeSubscriptionsContext'
import {getPlanByPriceId, isUpgradedPlan} from '../../../utils/licenseUtils'
import {ChangePlan} from '../../../types/Stripe'

interface PaymentDetailsProps {
	stripe: Stripe
	isChangePlan?: boolean
}

export const PaymentDetails = ({stripe, isChangePlan}: PaymentDetailsProps) => {

	const {token} = useUserContext()
	const elements = useElements()
	const {showFeedback} = useFeedbackContext()
	const {currentSubscription, setSubscriptionSchedule} = useStripeSubscriptionsContext()
	const {planId: newPlanId, selectedPlan, seats} = usePaymentContext()

	const currentPlan = getPlanByPriceId(currentSubscription!.plan.id)

	const [isLoading, setIsLoading] = useState(false)

	const onSubmit = async (event) => {
		event.preventDefault()
		if (!stripe || !elements) return
		setIsLoading(true)

		const {error} = await stripe.confirmPayment({
			elements,
			confirmParams: {return_url: `${window.location.origin}/paymentSuccess`},
		})

		if (!error && isChangePlan && newPlanId && selectedPlan && currentSubscription) {
			const changePlanInfo: ChangePlan = {
				currentPlanId: currentSubscription.plan.id,
				newPlanId: newPlanId,
				action: isUpgradedPlan(currentPlan, selectedPlan, currentSubscription.quantity, seats) ? 'upgrade' : 'downgrade',
				seats
			}

			updateSubscription(token, changePlanInfo).then(setSubscriptionSchedule)
		}

		if (error.type === 'card_error' || error.type === 'validation_error') {
			showFeedback(error.message ?? 'There was an error with your credit card', '', 'error')
		} else {
			showFeedback('An unexpected error occurred.', '', 'error')
		}
		 setIsLoading(false)
	}

	return <>
		<PaymentElement/>
		<Button variant='contained' className='purchaseButton' fullWidth disabled={!stripe || !elements} onClick={onSubmit}>Submit</Button>
		{isLoading && <Spinner/>}
		<Typography className='paymentConditions'>By providing us with your card details, you allow Narus to charge your card for future payments according to the
			stipulated conditions.</Typography>
	</>
}