import {ChangeEvent, FC, useEffect, useRef, useState} from 'react'
import {Box, Divider, Drawer, InputAdornment, TextField, IconButton, Paper, Badge, Alert} from '@mui/material'
import './Chat.scss'
import {usePromptCreationContext} from '../../context/PromptCreationContext'
import {Message} from './Message'
import {PromptChatDetail} from '../../types/PromptChatDetail'
import {ChatMessage} from '../../types/ChatMessage'
import {MessageLoading} from './MessageLoading'
import SaveChatCompilation from '../saveChatCompilation/SaveChatCompilation'
import {ReactComponent as MagicWandIcon} from '../../images/magic-wand.svg'
import {useToggleDrawerContext} from '../../context/ToggleDrawerContext'
import SaveChatHistory from '../saveChatHistory/SaveChatHistory'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {OptimizationSettingsDialog} from '../aiAdvancedSettings/OptimizationSettingsDialog'
import {useStripeSubscriptionsContext} from '../../context/StripeSubscriptionsContext'
import {useUserBudgetContext} from '../../context/UserBudgetContext'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import {useChatsContext} from '../../context/ChatsContext'
import {useChatMessagesContext} from '../../context/ChatMessagesContext'
import {FileUploadIconButton} from '../fileUpload/FileUploadIconButton'
import {FileUploadChips} from '../fileUpload/FileUploadChips'
import {useFilesContext} from '../../context/FilesContext'
import {isModelUnbinded} from '../../utils/fileUtils'
import {getPropValue, hasProp, not} from '../../utils/genericUtils'
import {AIModelID} from '../../types/AiModel'
import Spinner from '../spinner/Spinner'
import Tooltip from '@mui/material/Tooltip'
import {FileUpload} from '../../types/File'
import {isModelEnabledForUser} from '../../helpers/AiModelHelper'
import {useUserInfoContext} from '../../context/UserInfoContext'
import {useAiModelsContext} from '../../context/AIModelsContext'
import {useUserGroupContext} from '../../context/UserGroupContext'

type Props = {
	promptChatDetails: PromptChatDetail
	tempPromptId: string
	generateAiOutputHandler: (modelId: AIModelID, isRegeneratedMessage: boolean, userPromptFromInitial?: string, previousMessages?: string[], files?: FileUpload[]) => Promise<void>
	optimizedPromptHandler: (modelId?: AIModelID) => Promise<void>
	onExit: () => void
}

export const Chat: FC<Props> = ({
	promptChatDetails, 
	tempPromptId, 
	generateAiOutputHandler, 
	optimizedPromptHandler,
	onExit
}) => {
	const {modelId} = promptChatDetails

	const {
		userPrompt, aiPrompt, promptChats, setUnsavedChanges, format, tone, language,
		isOptimizedPrompt, aiPromptLoading, aiOutputLoading, setAiPrompt, setUserPrompt, setPromptChats, setAiOutputLoading
	} = usePromptCreationContext()
	const {displayDrawerSaveChatCompilation, displayDrawerSaveChatHistory, toggleDrawer} = useToggleDrawerContext()
	const {isTrial} = useStripeSubscriptionsContext()
	const {hasBudgetRemaining} = useUserBudgetContext()
	const {files, clearFiles, isLoading} = useFilesContext()
	const {chatIdsForCurrentConversation, chatsByUser, setChat, loadValuesFromChat, clearChatFields} = useChatsContext()
	const {chatId} = useChatMessagesContext()
	const {userInfo} = useUserInfoContext()
	const {aiModels} = useAiModelsContext()
	const {userGroup} = useUserGroupContext()

	const [messages, setMessages] = useState<ChatMessage[]>(promptChats.find(promptChat => promptChat.modelId === modelId)?.messages || [])
	const [botMessageCount, setBotMessageCount] = useState<number>(0)
	const [userHasScrolledUp, setUserHasScrolledUp] = useState<boolean>(false)
	const [chatMessageLoading, setChatMessageLoading] = useState<boolean>(aiOutputLoading)
	const [regenerateChatMessageLoading, setRegenerateChatMessageLoading] = useState<boolean>(false)
	const [showOptimizePromptSettings, setShowOptimizePromptSettings] = useState<boolean>(false)

	const messagesEndRef = useRef<HTMLDivElement>(null)
	const autoScroll = useRef<boolean>(false)
	const chatInputRef = useRef<HTMLDivElement>(null)

	const modelUnbindedFiles = files.filter(isModelUnbinded(modelId))
	const areFileUploadsCompleted = modelUnbindedFiles.every(hasProp('state', 'completed'))
	const isModelEnabled = isModelEnabledForUser(modelId, aiModels, userInfo, userGroup)
	const sendDisabled = !areFileUploadsCompleted || !isModelEnabled || !hasBudgetRemaining
	const isInteractDisabled = !hasBudgetRemaining || !isModelEnabled

	useEffect(() => {
		if (!chatMessageLoading && aiOutputLoading && messages.length % 2 !== 0) setChatMessageLoading(true) // odd messages means user has sent a new message
	}, [aiOutputLoading, chatMessageLoading, messages.length])

	useEffect(() => {
		const messagesForCurrentModel = promptChats.find(promptChat => promptChat.modelId === modelId)?.messages || []
		setMessages(messagesForCurrentModel)
		const newBotMessagesCount = messagesForCurrentModel.filter(message => message.sender === 'bot').length

		if (messagesForCurrentModel.some(message => message.loading) || newBotMessagesCount > botMessageCount) {
			setChatMessageLoading(false)
		}

		if (!userHasScrolledUp) {
			autoScroll.current = true
			messagesEndRef.current?.scrollIntoView(false)
		}
		setBotMessageCount(newBotMessagesCount)
	}, [promptChats, modelId, chatMessageLoading, botMessageCount, aiOutputLoading, messages, userHasScrolledUp])

	useEffect(() => {
		const chatId = chatIdsForCurrentConversation.find(chatIdForCurrentConversation => chatIdForCurrentConversation.modelId === modelId)?.chatId
		const currentChat = chatsByUser.find(chat => chat.chatId === chatId)

		if (currentChat) {
			setChat(currentChat)
			loadValuesFromChat()
		} else {
			clearChatFields()
		}
	}, [modelId, chatIdsForCurrentConversation, chatsByUser, loadValuesFromChat, clearChatFields, chatId, setChat])

	const handleSend = () => {
		if (userPrompt.trim() !== '' && !sendDisabled) {
			setUserHasScrolledUp(false)
			if (isOptimizedPrompt()) {
				optimizedPromptHandler(modelId)
			} else {
				setPromptChats((previousValue: PromptChatDetail[]): PromptChatDetail[] => {
					const chatIndex = previousValue.findIndex(previousChat => previousChat.modelId === modelId)
					if (chatIndex === -1) return previousValue

					const updatedMessages: ChatMessage[] = [
						...previousValue[chatIndex].messages,
						{sender: 'user', text: userPrompt, optimized: false, files: modelUnbindedFiles.map(getPropValue('metadata'))}
					]

					const updatedChat: PromptChatDetail = {
						...previousValue[chatIndex],
						messages: updatedMessages,
						compilationSummary: {isChatUpdated: true}
					}

					return [...previousValue.slice(0, chatIndex), updatedChat, ...previousValue.slice(chatIndex + 1)]
				})
				setChatMessageLoading(true)
				generateAiOutputHandler(modelId, false, userPrompt, [], files)
			}
			clearFiles(not(isModelUnbinded(modelId)))
			setAiOutputLoading(false)
			setUnsavedChanges(true)
			setUserPrompt('')
			setTimeout(() => {
				autoScroll.current = true
				messagesEndRef.current?.scrollIntoView({behavior: 'smooth', block: 'end'})
			}, 200)
		}
	}

	const handleEnterKeyPressed = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault()
			if (!isLoading) handleSend()
		}
	}

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (aiPrompt !== '') {
			setAiPrompt(event.target.value)
		} else {
			setUserPrompt(event.target.value)
		}
	}

	const regenerateOutputHandler = (regenerateFromError?: boolean) => {
		if (aiOutputLoading) return

		setChatMessageLoading(true)
		setRegenerateChatMessageLoading(true)
		const updatedChatMessages = promptChatDetails.messages
		const lastUserPromptIndex = updatedChatMessages.length - 2
		const messageToRegenerate = updatedChatMessages.pop()

		setPromptChats((previousValue: PromptChatDetail[]) => {
			const previousChatUpdated = previousValue.find(previousChat => previousChat.modelId === modelId)
			if (!previousChatUpdated) return previousValue

			previousChatUpdated.messages = updatedChatMessages
			return Object.assign([], previousValue)
		})
		const regeneratedOutputs = [...(messageToRegenerate!.regeneratedOutputs || []), messageToRegenerate!.text]
		generateAiOutputHandler(modelId, true, updatedChatMessages[lastUserPromptIndex].text, regenerateFromError ? [] : regeneratedOutputs, files).then(() => {
			setAiOutputLoading(false)
			setRegenerateChatMessageLoading(false)
		})
		autoScroll.current = true
		messagesEndRef.current?.scrollIntoView({behavior: 'smooth', block: 'end'})
	}

	const handleOnScroll = () => {
		if (!autoScroll.current) {
			setUserHasScrolledUp(true)
		}
		autoScroll.current = false
	}

	useEffect(() => {
		const handleResize = () => {
			if (chatInputRef.current) {
				autoScroll.current = true
			}
		}

		const observer = new ResizeObserver(handleResize)
		const ref = chatInputRef.current

		if (ref) {
			observer.observe(ref)
		}

		return () => {
			if (ref) {
				observer.unobserve(ref)
			}
		}
	}, [chatInputRef])

	return <Box className={`chatContainer ${isTrial ? 'chatContainerTrial' : ''}`}>
		<Box className='chatMessageContainer' onScroll={handleOnScroll}>
			{messages.map((message, index) =>
				<Message key={`message-${index}`} message={message} modelId={modelId}
				         isLastMessage={promptChatDetails.messages.length - 1 === index}
				         onRegenerateOutput={() => regenerateOutputHandler(false)}/>)}
			{chatMessageLoading &&
                <MessageLoading message={{text: regenerateChatMessageLoading ? 'Regenerating output...' : 'Generating output...', sender: 'bot'}} modelId={modelId}/>}
			{aiPromptLoading &&
				<MessageLoading message={{text: regenerateChatMessageLoading ? 'Regenerating prompt...' : 'Generating prompt...', sender: 'user'}} modelId={modelId} isUserMessage={true}/>}
			<Box ref={messagesEndRef}></Box>
		</Box>
		<Box className='chatInputContainer' ref={chatInputRef}>
			{!isModelEnabled && <Alert severity='warning' className='modelDisabledAlert'>The model you have selected is no longer available, please create a new chat or talk to the administrator.</Alert>}
			<FileUploadChips fileUploads={modelUnbindedFiles}/>
			<Paper className='chatInput' component='div'>
				<TextField
					fullWidth
					multiline
					disabled={isInteractDisabled}
					placeholder='Write your prompt here...'
					variant='outlined'
					onKeyDown={handleEnterKeyPressed}
					value={aiPrompt !== '' ? aiPrompt : userPrompt}
					onChange={handleInputChange}
					className={`promptInput ${isInteractDisabled ? 'disabled' : ''}`}
					maxRows={10}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<Box className='chatInputAdornment'>
									<IconButton edge='start' onClick={() => setShowOptimizePromptSettings(true)}
												disabled={!isInteractDisabled}
												className={'promptOptimizationButton promptOptimizationButtonChat'}>
										<Badge color='secondary' className='optimizationBadge' badgeContent={[format, tone, language].filter(optimizationParam => optimizationParam).length}>
											{!isInteractDisabled ? <MagicWandIcon/> : <AutoFixHighIcon className='magicWandIcon disabled'/>}
										</Badge>
									</IconButton>
									<FileUploadIconButton modelId={modelId} conversationId={chatId}/>
								</Box>
								<Divider orientation='vertical' className='chatInputDivider'/>
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position='end'>
								<Box className={`arrowIconContainer ${isInteractDisabled ? 'disabled' : ''}`}>
									{isLoading ? <Tooltip title='Uploading file...' placement='top'>
										<Box><Spinner/></Box>
									</Tooltip> : <ArrowForwardIcon onClick={handleSend}/>}
								</Box>
							</InputAdornment>)
					}}
				/>
			</Paper>
		</Box>
		<Drawer anchor='right' open={displayDrawerSaveChatCompilation}
		        onClose={toggleDrawer(false, 'SAVE_CHAT_COMPILATION_DRAWER')}>
			<SaveChatCompilation promptChatDetails={promptChatDetails} tempPromptId={tempPromptId} onUpdate={onExit}/>
		</Drawer>
		<Drawer anchor='right' open={displayDrawerSaveChatHistory}
				onClose={toggleDrawer(false, 'SAVE_CHAT_HISTORY_DRAWER')}>
			<SaveChatHistory modelId={modelId} tempPromptId={tempPromptId}/>
		</Drawer>
		<OptimizationSettingsDialog showSliders={showOptimizePromptSettings}
									onClose={() => setShowOptimizePromptSettings(false)}/>
	</Box>
}