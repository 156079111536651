import {SignUp} from '@clerk/clerk-react'
import {useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import {validateAwsMarketplaceCustomerSignature} from '../../utils/JwtUtils'
import {PageView, TrackActionEvent} from '../../service/SegmentService'
import {Box, Typography, Link} from '@mui/material'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import CableIcon from '@mui/icons-material/Cable'
import {MobileBanner} from '../mobileBanner/MobileBanner'
import {ReactComponent as NarusLogoIcon} from '../../images/narus-logo.svg'
import './SignUpPage.scss'

export const SignUpPage = () => {

  const [searchParams] = useSearchParams()

  useEffect(() => {
    PageView('SignUp')

    const token = searchParams.get('jwt')
    const awsMarketplaceCustomerData = validateAwsMarketplaceCustomerSignature(token ?? '')
    localStorage.setItem('awsMarketplaceCustomerData', JSON.stringify(awsMarketplaceCustomerData))
  }, [searchParams])

  const trackTermsLinkClicked = (linkType: string) => {
    TrackActionEvent('Sign up link clicked', undefined, {type: linkType})
  }

  return <>
    <Box className='signUpContainer'>
      <Box className='contentColumn'>
        <Box className='contentColumnWrapper'>
          <NarusLogoIcon className='narusLogo'/>
          <Typography className='title'>Welcome to Narus!</Typography>
          <Typography className='explanation subtitle'>Narus is your new private and secure AI chat portal. Supercharge your productivity by:</Typography>

          <Box className='benefitsContainer'>
            <Box className='benefit'>
              <AutoFixHighIcon className='icon'/>
              <Typography className='explanation'>Using tools to help you write better prompts to get the best results.</Typography>
            </Box>
            <Box className='benefit'>
              <AutoAwesomeIcon className='icon'/>
              <Typography className='explanation'>Sending a single query to many AIs to quickly double check the answers</Typography>
            </Box>
            <Box className='benefit'>
              <CableIcon className='icon'/>
              <Typography className='explanation'>Choosing which AI you chat with to ensure you're using the right tool for the job.</Typography>
            </Box>
            <Box className='benefit'>
              <CableIcon className='icon'/>
              <Typography className='explanation'>Collaborating with your team by sharing the most useful prompts so everyone wins.</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className='contentColumn signUp'>
        <SignUp
          appearance={{
            layout: {
              showOptionalFields: false
            }
          }}
        />
        <Box className='termsSection'>
          <Typography>By signing up, I agree to the <Link className='termsLink'
                                                          href='https://www.kolekti.com/kolekti-eula'
                                                          target='_blank'
                                                          onClick={() => trackTermsLinkClicked('EULA')}
            >Kolekti EULA</Link>, <Link className='termsLink'
                                        href='https://www.theadaptavistgroup.com/policy/terms'
                                        target='_blank'
                                        onClick={() => trackTermsLinkClicked('website_terms')}
            >Website terms</Link>, <Link className='termsLink'
                                         href='https://www.narus.ai/privacy-policy'
                                         target='_blank'
                                         onClick={() => trackTermsLinkClicked('privacy_policy')}
            >Privacy Policy</Link> and <Link className='termsLink'
                                             href='https://static.adaptavistassets.com/downloads/kolekti_dpa.pdf'
                                             target='_blank'
                                             onClick={() => trackTermsLinkClicked('DPA')}
            >DPA</Link>
          </Typography>
        </Box>
      </Box>
    </Box>
    <MobileBanner/>
  </>
}