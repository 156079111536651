import {FC, PropsWithChildren} from 'react'
import {UsersAccessContextProvider} from '../context/UsersAccessContext'
import {StatisticsContextProvider} from '../context/StatisticsContext'
import {AuditingLogsContextProvider} from '../context/AuditingLogsContext'
import {OrganizationContextProvider} from '../context/OrganizationContext'
import {AdminEmailsContextProvider} from '../context/AdminEmailsContext'
import {WarningsContextProvider} from '../context/WarningsContext'
import {GroupsContextProvider} from '../context/GroupsContext'
import {AdminNavigationContextProvider} from '../context/AdminNavigationContextProvider'
import {DataGridContextProvider} from '../context/DataGridContext'
import {BudgetsContextProvider} from '../context/BudgetContext'
import {useUserInfoContext} from '../context/UserInfoContext'
import {PaymentContextProvider} from '../context/PaymentContext'
import {AiPricingContextProvider} from '../context/AIPricingContext'

export const AdminProviders: FC<PropsWithChildren> = ({
                                                          children
                                                      }) => {
    const {userInfo} = useUserInfoContext()
    const isAdmin = userInfo?.isAdmin

    return isAdmin ? (
        <UsersAccessContextProvider>
            <AiPricingContextProvider>
                <AuditingLogsContextProvider>
                    <OrganizationContextProvider>
                        <AdminEmailsContextProvider>
                            <WarningsContextProvider>
                                <GroupsContextProvider>
                                    <StatisticsContextProvider>
                                        <AdminNavigationContextProvider>
                                            <DataGridContextProvider>
                                                <BudgetsContextProvider>
                                                    <PaymentContextProvider>
                                                        {children}
                                                    </PaymentContextProvider>
                                                </BudgetsContextProvider>
                                            </DataGridContextProvider>
                                        </AdminNavigationContextProvider>
                                    </StatisticsContextProvider>
                                </GroupsContextProvider>
                            </WarningsContextProvider>
                        </AdminEmailsContextProvider>
                    </OrganizationContextProvider>
                </AuditingLogsContextProvider>
            </AiPricingContextProvider>
        </UsersAccessContextProvider>
    ) : children
}