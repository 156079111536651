import {FC, useCallback, useEffect} from 'react'
import {Box, Button, Typography} from '@mui/material'
import {ACCOUNT_CREATION_COMPONENT_MAP, ACCOUNT_CREATION_CONFIG} from '../../constants/AccountCreationConstants'
import {useAccountCreationContext} from '../../context/AccountCreationContext'
import {StepIndex} from '../../types/AccountCreation'
import './AccountCreationForm.scss'
import {PageView, TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useMarketingCommsContext} from '../../context/MarketingCommsContext'
import {useUserOrganizationContext} from '../../context/UserOrganizationContext'

type AccountCreationFormProps = {
    index: StepIndex
}

export const AccountCreationForm: FC<AccountCreationFormProps> = ({index}) => {
    const {
        valid,
        stepsState,
        submitStep,
        form
    } = useAccountCreationContext()
    const {userOrganization} = useUserOrganizationContext()

    const {companyDomains, companyName, adminEmails, models} = form
    const {user} = useUser()
    const {trackMarketingEvent, isMarketingEventSent} = useMarketingCommsContext()

    const {actionTitle, controls, formTitle, validators, pageAnalytics} = ACCOUNT_CREATION_CONFIG[index]
    const state = stepsState[index]
    const loading = state === 'loading'

    const actionButtonText = loading ? 'in progress' : actionTitle
    const actionButtonDisabled = !validators.every(key => valid[key]) || state === 'loading'

    const controlComponents = controls.map(value => {
        const Component = ACCOUNT_CREATION_COMPONENT_MAP[value]
        return <Component key={value}/>
    })

    const handleActionButtonClicked = useCallback(() => {
        const trackMap: Record<StepIndex, () => Promise<void>> = {
            0: () => TrackActionEvent('Create Organization', user?.externalId ?? user?.id, {
                company_name: companyName,
                company_domains: companyDomains,
                action: 'continue'
            }),
            1: () => TrackActionEvent('Admins Defined', user?.externalId ?? user?.id, {
                company_name: companyName,
                company_id: userOrganization.organizationId,
                company_admin_emails: adminEmails,
                action: 'continue'
            }),
            2: () => TrackActionEvent('AI Models', user?.externalId ?? user?.id, {
                company_name: companyName,
                company_id: userOrganization.organizationId,
                company_ai_models: models.map(({modelId}) => modelId),
                action: 'finalize'
            })
        }

        trackMap[index]()
        submitStep(index)
    }, [index, submitStep, user?.id, user?.externalId, companyName, companyDomains, adminEmails, models, userOrganization.organizationId])

    useEffect(() => {
        if (state !== 'completed') PageView(pageAnalytics)
    }, [pageAnalytics, state])

    useEffect(() => {
        if (!isMarketingEventSent) trackMarketingEvent()
    }, [isMarketingEventSent, trackMarketingEvent])

    return <Box className='AccountCreation_Main AccountCreationForm'>
        <Typography variant='h3'>{formTitle}</Typography>
        <Box className='AccountCreationForm_Controls'>
            {controlComponents}
        </Box>
        <Box className='AccountCreationForm_Actions'>
            <Button className='AccountCreationForm_Action' variant='contained' size='large' disabled={actionButtonDisabled} onClick={handleActionButtonClicked}>
                {actionButtonText}
            </Button>
        </Box>
    </Box>
}