import {useEffect} from 'react'
import {PageView} from '../../../service/SegmentService'
import {PricingContent} from './PricingContent'
import {Box} from '@mui/material'
import './PricingTable.scss'
import {useStripeSubscriptionsContext} from '../../../context/StripeSubscriptionsContext'
import {useNavigate} from 'react-router-dom'
import {isTrialSubscription} from '../../../utils/subscriptionUtils'

export const PricingTable = () => {

    const navigate = useNavigate()
    const {currentSubscription, isActiveSubscription} = useStripeSubscriptionsContext()

    useEffect(() => {
        if (isActiveSubscription && currentSubscription && !isTrialSubscription(currentSubscription)) return navigate('/')
        PageView('Pricing')
    })

    return <Box className='pricingTableContainer'>
        <PricingContent isChangePlan={false}/>
    </Box>
}