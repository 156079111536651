import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {Box, Stack, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PaymentsIcon from '@mui/icons-material/PaymentsOutlined'
import './OverallBudget.scss'
import {useBudgetsContext} from '../../../../context/BudgetContext'
import {BudgetForm} from './BudgetForm'
import {BudgetDetail} from './BudgetDetail'
import Spinner from '../../../spinner/Spinner'

export const OverallBudget = () => {
    const {toggleDrawer} = useToggleDrawerContext()
    const {overallBudget, loading} = useBudgetsContext()

    return <Stack className='organisationLimit_Container'>
        <Box className='organisationLimit_TitleContainer'>
            <Box className='organisationLimit_Title'>
                <Box className='organisationLimit_TitleIcon'>
                    <PaymentsIcon fontSize='small'/>
                </Box>
                <Typography variant='h4'>Set organisation limit</Typography>
            </Box>
            <CloseIcon onClick={toggleDrawer(false, 'EDIT_OVERALL_BUDGET')} cursor='pointer'
                       className='organisationLimit_CloseIcon'/>
        </Box>
        {loading ? <Spinner/> : (overallBudget ? <BudgetDetail budget={overallBudget}/> : <BudgetForm/>)}
    </Stack>
}