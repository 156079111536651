import { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'
import {AiPricing} from '../types/AiPricing'
import {useUserContext} from './UserContext'
import {getAiPricing} from '../service/persistenceService'

export type AiPricingContextValue = {
    aiPricing: AiPricing[]
}

const DEFAULT_PRICING_CONTEXT: AiPricingContextValue = {
    aiPricing: []
}

const AiPricingContext = createContext<AiPricingContextValue>(DEFAULT_PRICING_CONTEXT)

export const useAiPricingContext = () => useContext(AiPricingContext)

export const AiPricingContextProvider: FC<PropsWithChildren> = ({
    children
}) => {
    const {token} = useUserContext()

    const [aiPricing, setAiPricing] = useState<AiPricing[]>([])

    useEffect(() => {
        getAiPricing(token).then(setAiPricing)
    }, [token])

    const value: AiPricingContextValue = useMemo(() => ({
        aiPricing
    }), [aiPricing])

    return <AiPricingContext.Provider value={value}>
        {children}
    </AiPricingContext.Provider>
}