import {createContext, FC, PropsWithChildren, useContext} from 'react'
import {AIModel, OptionalAIModelMap} from '../types/AiModel'
import {useAiModels} from '../hooks/useAiModels'

export type AiModelsConfig = {
    retrieveFeedbackVisible?: boolean
}

interface Props {
    config?: AiModelsConfig
}

export interface AiModelsContextValue {
    loading: boolean
    aiModels: AIModel[]
    aiModelMap: OptionalAIModelMap
    updateAiModelEnablement: (isChecked: boolean, aiModel: AIModel) => Promise<void>
    deleteAiModel: (id: string) => Promise<void>
    addAiModel: (id: string, apiKey: string, modelName: string) => Promise<void>
    updateAiModelConfig: (apiKey: string | undefined, modelName: string, isUpdate: boolean, modelId: string) => Promise<void>
}

const DEFAULT_MODELS_CONTEXT: AiModelsContextValue = {
    loading: false,
    aiModels: [],
    aiModelMap: {},
    updateAiModelEnablement: () => Promise.resolve(),
    deleteAiModel: () => Promise.resolve(),
    addAiModel: () => Promise.resolve(),
    updateAiModelConfig: () => Promise.resolve()
}

const DEFAULT_MODELS_CONFIG: AiModelsConfig = {
    retrieveFeedbackVisible: true
}

export const AiModelsContext = createContext<AiModelsContextValue>(DEFAULT_MODELS_CONTEXT)

export const useAiModelsContext = () => useContext(AiModelsContext)

export const AiModelsContextProvider: FC<PropsWithChildren<Props>> = ({
    config, 
    children
}) => {
    const value = useAiModels(config ?? DEFAULT_MODELS_CONFIG)

    return <AiModelsContext.Provider value={value}>
        {children}
    </AiModelsContext.Provider>
}