import {Outlet} from 'react-router-dom'
import {AccountCreationContextProvider} from '../../context/AccountCreationContext'
import {UserOrganizationContextProvider} from '../../context/UserOrganizationContext'
import {MarketingCommsContextProvider} from '../../context/MarketingCommsContext'
import {UserContextProvider} from '../../context/UserContext'
import './AccountCreation.scss'

export const AccountCreationWrapper = () => (
    <UserContextProvider>
        <UserOrganizationContextProvider>
            <MarketingCommsContextProvider>
                <AccountCreationContextProvider>
                    <Outlet/>
                </AccountCreationContextProvider>
            </MarketingCommsContextProvider>
        </UserOrganizationContextProvider>
    </UserContextProvider>
)
