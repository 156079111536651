import {SignIn} from '@clerk/clerk-react'
import {useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import {validateAwsMarketplaceCustomerSignature} from '../../utils/JwtUtils'
import {PageView, TrackActionEvent} from '../../service/SegmentService'
import {Box, Typography, Link} from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import {MobileBanner} from '../mobileBanner/MobileBanner'
import {NarusLogo} from '../icons/NarusLogo'
import './SignInPage.scss'

export const SignInPage = () => {

  const [searchParams] = useSearchParams()

  useEffect(() => {
    PageView('SignIn')

    const token = searchParams.get('jwt')
    const awsMarketplaceCustomerData = validateAwsMarketplaceCustomerSignature(token ?? '')
    localStorage.setItem('awsMarketplaceCustomerData', JSON.stringify(awsMarketplaceCustomerData))
  }, [searchParams])

  const trackTermsLinkClicked = (linkType: string) => {
    TrackActionEvent('Sign in link clicked', undefined, {type: linkType})
  }

  return <>
    <Box className='signInContainer'>
      <Box className='titleRow'>
        <NarusLogo/>
      </Box>
      <Box className='login'>
        <SignIn
          appearance={{
            layout: {
              logoImageUrl: '/narus-logo.png'
            }
          }}
        />
        <Box className='termsSection'>
          <Typography>By signing in, I agree to the <Link className='termsLink'
                                                          href='https://www.theadaptavistgroup.com/policy/terms'
                                                          target='_blank'
                                                          onClick={() => trackTermsLinkClicked('website_terms')}
          >Adaptavist Terms of service<LaunchIcon className='redirectIcon'/>
          </Link></Typography><Typography>and <Link className='termsLink'
                            href='https://www.narus.ai/privacy-policy'
                            target='_blank'
                            onClick={() => trackTermsLinkClicked('privacy_policy')}
          >Privacy policy<LaunchIcon className='redirectIcon'/></Link>
          </Typography>
        </Box>
      </Box>
    </Box>
    <MobileBanner/>
  </>
}