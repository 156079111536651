import {Box, Grid} from '@mui/material'
import {CustomDateRangePicker} from './CustomDateRangePicker'
import {AiUsageChart} from './charts/AiUsageChart'
import {CostPerAIModel} from './charts/CostPerAIModel'
import {UsageTable} from './charts/UsageTable'
import {getDateMonthsAgo} from '../../utils/dateUtils'
import {getMonthsAuditLimitBasedOnSubscriptionPlan} from '../../utils/licenseUtils'
import {useStripeSubscriptionsContext} from '../../context/StripeSubscriptionsContext'
import './AdminOverview.scss'

export const AdminOverview = () => {

	const {currentSubscription, isCurrentSubscriptionGrowth, isCurrentSubscriptionScale} = useStripeSubscriptionsContext()

	return (
		<Box className='AdminOverviewContainer'>
			<Grid item xs={12} className='datePickerContainer'>
				<CustomDateRangePicker currentTab='OVERVIEW'
				                       maxBackDate={isCurrentSubscriptionGrowth || isCurrentSubscriptionScale ? getDateMonthsAgo(getMonthsAuditLimitBasedOnSubscriptionPlan(currentSubscription)) : undefined}/>
			</Grid>
			<Grid container item xs={12} columnSpacing={'12px'} rowSpacing={'45px'}>
				<Grid item xs={7}>
					<AiUsageChart/>
				</Grid>
				<Grid item xs={5}>
					<CostPerAIModel/>
				</Grid>
				<Grid item xs={12}>
					<UsageTable/>
				</Grid>
			</Grid>
		</Box>
	)
}