import {FC} from 'react'
import {FileId, FileMetadata} from '../../types/File'
import {Box, IconButton, Typography} from '@mui/material'
import DocumentIcon from '@mui/icons-material/DescriptionOutlined'
import './MessageFiles.scss'
import ClearIcon from '@mui/icons-material/Clear'
import {usePromptCreationContext} from '../../context/PromptCreationContext'

type Props = {
    files?: FileMetadata[]
}

export const MessageFiles: FC<Props> = ({
    files
}) => {
    const {removeFileFromPrompt} = usePromptCreationContext()

    const handleDeleteFile = (fileId: FileId) => {
        removeFileFromPrompt(fileId)
    }

    return files?.length
        ? <Box className='messageFilesContainer'>
            {files.map(({id, name, timestamp}, index) => (
                <Box className='messageFilesItem' key={`file-${index}`}>
                    <DocumentIcon className='itemIcon'/>
                    <Box className='itemDescription'>
                        <Typography className='itemName'>{name}</Typography>
                        <Typography className='itemDate'>{timestamp}</Typography>
                    </Box>
                    <IconButton aria-label='delete' onClick={() => handleDeleteFile(id)}>
                        <ClearIcon />
                    </IconButton>
                </Box>
            ))}
        </Box> : <></>
}
