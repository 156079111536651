import {useNavigate} from 'react-router-dom'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useSearchContext} from '../../context/SearchContext'
import {ReactComponent as NarusLogoIcon} from '../../images/narus-logo.svg'
import './NarusLogo.scss'

interface NarusLogoProps {
	width?: number
	height?: number
}

export const NarusLogo = ({width, height}: NarusLogoProps) => {

	const navigate = useNavigate()
	const {user} = useUser()
	const {clearSearch} = useSearchContext()

	const iconClickHandler = () => {
		TrackActionEvent('Menu clicked', user?.externalId ?? user?.id, {path: '/'})
		clearSearch()
		navigate('/')
	}

	return <div className='headerLogo' onClick={iconClickHandler}>
		<NarusLogoIcon className='narusLogo'/>
	</div>
}