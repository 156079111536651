import {Box, Button, Grid, InputAdornment, TextField, Typography} from '@mui/material'
import React, {ChangeEvent, useState} from 'react'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {useUser} from '@clerk/clerk-react'
import './BudgetDetail.scss'
import {Budget} from '../../../../types/Budget'
import {formatDateStringToLocaleDate} from '../../../../helpers/DateHelpers'
import {useUsersAccessContext} from '../../../../context/UsersAccessContext'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useBudgetsContext} from '../../../../context/BudgetContext'

type BudgetDetailProps = {
	budget: Budget
}

export const BudgetDetail = ({budget}: BudgetDetailProps) => {
	const {toggleDrawer} = useToggleDrawerContext()
	const {user} = useUser()
	const {usersInfo} = useUsersAccessContext()
	const {updateBudgetAmount, closeCurrentBudget} = useBudgetsContext()

	const [amount, setAmount] = useState<string>(budget.amount.toString())
	const [amountValidationError, setAmountValidationError] = useState<boolean>(false)
	
	const onUpdateBudgetHandler = async (event: React.MouseEvent) => {
		const updatedAmount = parseFloat(amount)
		if (isNaN(updatedAmount) || updatedAmount <= 0) {
			setAmountValidationError(true)
			return
		}
		TrackActionEvent('Budget', user?.externalId ?? user?.id, {action: 'update', type: 'overall'})
		const updatedBudget = await updateBudgetAmount(budget, updatedAmount)
		if (updatedBudget !== undefined) toggleDrawer(false, 'EDIT_OVERALL_BUDGET')(event)
	}

	const onAmountHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setAmountValidationError(false)
		setAmount(event.target.value)
	}

	const onCloseCurrentBudget = async (event: React.MouseEvent) => {
		TrackActionEvent('Budget', user?.externalId ?? user?.id, {action: 'close', type: 'overall'})
		const updatedBudget = await closeCurrentBudget()
		if (updatedBudget !== undefined) toggleDrawer(false, 'EDIT_OVERALL_BUDGET')(event)

	}

	return <>
		<Box className='budgetDetailContainer'>
			<Box className='budgetTitleContainer'>
				<Typography variant='h4'>Current organization budget</Typography>
				<Button variant='outlined' onClick={onCloseCurrentBudget}>Close budget</Button>
			</Box>
			<Box className='budgetFieldsContainer'>
				<Grid container className='budgetFieldContainer'>
					<Grid item xs={4}><Typography>Created</Typography></Grid>
					<Grid item xs={8}>
						<Typography>
							{usersInfo.find(userInfo => userInfo.userId === budget.creatorUserId)?.userFullName ?? ''}
						</Typography>
					</Grid>
				</Grid>
				<Box className='budgetFieldContainer'>
					<Grid item xs={4}><Typography>Date of creation</Typography></Grid>
					<Grid item xs={8}><Typography>{formatDateStringToLocaleDate(budget.creationDate)}</Typography></Grid>
				</Box>
				<Box className='budgetFieldContainer'>
					<Grid item xs={4}><Typography>Duration</Typography></Grid>
					<Grid item xs={8}>
						<Typography>
							{formatDateStringToLocaleDate(budget.startDate)} - {formatDateStringToLocaleDate(budget.endDate)}
						</Typography>
					</Grid>
				</Box>
				<Box className='budgetFieldContainer'>
					<Grid item xs={4}><Typography>Recurrence</Typography></Grid>
					<Grid item xs={8}><Typography>{budget.periodicityType}</Typography></Grid>
				</Box>
			</Box>
			<Box>
				<Typography className='budgetAmountTitle'>Budget amount</Typography>
				<TextField label='Amount' required fullWidth
				           InputProps={{startAdornment: <InputAdornment position='start'>$</InputAdornment>}}
				           onChange={onAmountHandler}
				           error={amountValidationError}
				           helperText={amountValidationError ? 'Amount must be a number greater than 0' : ''}
				           value={amount}/>
			</Box>
		</Box>
		<Box className='buttonsContainer'>
			<Button variant='outlined' onClick={toggleDrawer(false, 'EDIT_OVERALL_BUDGET')}>Cancel</Button>
			<Button variant='contained' onClick={onUpdateBudgetHandler}>Save</Button>
		</Box>
	</>
}