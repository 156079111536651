import {createContext, ReactNode, useContext, useEffect} from 'react'
import {UserInfo} from '../types/UserInfo'
import {useUserInfo} from '../hooks/useUserInfo'
import Spinner from '../components/spinner/Spinner'
import {AppGridWrapper} from '../components/appGridWrapper/AppGridWrapper'
import {AIModelID} from '../types/AiModel'
import {NoSeatsError} from '../components/error/NoSeatsError'
import { useNavigate } from 'react-router-dom'

interface UserInfoContextProviderProps {
	children: ReactNode
}

export interface UserInfoContextValue {
	loading: boolean
	userInfo: UserInfo | undefined
	updateUserModels: (selectedModels: AIModelID[]) => void
	updateUserInformation: (userInfo: UserInfo) => Promise<void>
}

const DEFAULT_USER_INFO_CONTEXT: UserInfoContextValue = {
	loading: false,
	userInfo: undefined,
	updateUserModels: () => {},
	updateUserInformation: () => Promise.resolve()
}

export const UserInfoContext = createContext<UserInfoContextValue>(DEFAULT_USER_INFO_CONTEXT)

export const useUserInfoContext = () => useContext(UserInfoContext)

export const UserInfoContextProvider = ({children}: UserInfoContextProviderProps) => {
	const value = useUserInfo()
	const { userInfo } = value
	const navigate = useNavigate()

	useEffect(() => {
		if (userInfo && !userInfo.isOnboarded) {
			navigate('/gettingStarted')
		}
	}, [userInfo, navigate])

	const displayUserChildren = () => {
		if (!userInfo) return <AppGridWrapper hideBanners><Spinner/></AppGridWrapper>
		if (userInfo.blocked) return <NoSeatsError/>
		return children
	}
	return <UserInfoContext.Provider value={value}>
		{ displayUserChildren() }
	</UserInfoContext.Provider>
}