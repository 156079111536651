import {useAiModelsContext} from '../../../../context/AIModelsContext'
import {Avatar, Button, Grid} from '@mui/material'
import Box from '@mui/material/Box'
import {AiModelIcon} from '../../../icons/AiModelIcon'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import React, {useState} from 'react'
import './EnabledAiModels.scss'
import CloseIcon from '@mui/icons-material/Close'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {UserInfo} from '../../../../types/UserInfo'
import {AIModelID} from '../../../../types/AiModel'
import {useUsersAccessContext} from '../../../../context/UsersAccessContext'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {isModelEnabledForUser} from '../../../../helpers/AiModelHelper'
import {getUserGroup} from '../../../../helpers/UserHelpers'
import {useGroupsContext} from '../../../../context/GroupsContext'

type EnabledAiModelsProps = {
	userInfo: UserInfo
}

export const EnabledAiModels = ({userInfo}: EnabledAiModelsProps) => {

	const {user} = useUser()
	const {aiModels} = useAiModelsContext()
	const {updateUserInfo} = useUsersAccessContext()
	const {toggleDrawer} = useToggleDrawerContext()
	const {groups, allGroupsMembers} = useGroupsContext()

	const [disabledModels, setDisabledModels] = useState<AIModelID[]>(aiModels.filter(aiModel => !isModelEnabledForUser(aiModel.id, aiModels, userInfo, getUserGroup(userInfo, allGroupsMembers, groups))).map(aiModel => aiModel.id))

	const onChangeDisabledModelsHandler = (enabled: boolean, aiModelID: AIModelID) => {
		setDisabledModels(previousValues => {
			if (enabled) previousValues = previousValues?.filter(modelId => modelId !== aiModelID)
			else previousValues = [...previousValues ?? [], aiModelID]
			return previousValues
		})
	}

	const saveUserInfoHandler = (event) => {
		TrackActionEvent('User access table', user?.externalId ?? user?.id, {action: 'save_user_models', disabled_models: disabledModels})
		const originallyDisabledModels = [...(userInfo.disabledAIModels ?? []), ...(getUserGroup(userInfo, allGroupsMembers, groups)?.disabledAIModels || [])]
		const enabledAIModels: AIModelID[] = [...(userInfo.enabledAIModels ?? []), ...originallyDisabledModels?.filter(previouslyDisabledAIModel => !disabledModels.includes(previouslyDisabledAIModel))]
		updateUserInfo({...userInfo, disabledAIModels: [...new Set(disabledModels)], enabledAIModels: [...new Set(enabledAIModels)]}).then(() =>
			toggleDrawer(false, 'EDIT_USER_DRAWER')(event)
		)
	}

	return <Grid container className='enabledAiModelsContainer'>
		<Grid item xs={12} container className='enabledAiModelsTitleWrapper'>
			<Grid item xs={1}>
				<Avatar src={userInfo.imageUrl}/>
			</Grid>
			<Grid item xs={10} className='enabledAiModelsUserName'>
				<Typography variant='h4'>{userInfo.userFullName}</Typography>
				<Typography variant='body1'>{userInfo.email}</Typography>
			</Grid>
			<Grid item xs={1} className='closerDrawerWrapper'>
				<CloseIcon onClick={toggleDrawer(false, 'EDIT_USER_DRAWER')}
				           cursor='pointer'
				           className='closeIcon'/>
			</Grid>
		</Grid>
		<Grid item xs={12}>
			<Typography variant='body1' className='enabledAiModelsExplanation'>
				Select which models the user can access
			</Typography>

			<Box className='enabledAiModelsListContainer'>
				{aiModels.filter(aiModel => aiModel.isEnabled).map((aiModel) =>
					<Grid container className={`aiModelRow ${aiModel.isEnabled ? '' : 'disabled'}`}
					      key={aiModel.id}>
						<Grid item md={11} xs={10}>
							<Box><AiModelIcon modelId={aiModel.id}/></Box>
							<Typography>{aiModel.name}</Typography>
						</Grid>
						<Grid item md={1} xs={2}>
							<Switch
								disabled={!aiModel.isEnabled}
								checked={aiModel.isEnabled && (!disabledModels?.length || disabledModels?.find(disabledModel => disabledModel === aiModel.id) === undefined)}
								onChange={(event) => onChangeDisabledModelsHandler(event.target.checked, aiModel.id)}
							/>
						</Grid>
					</Grid>)}
			</Box>
		</Grid>
		<Box className='saveDisabledModelsButtonContainer'>
			<Button className='saveChatButton' variant='contained' onClick={saveUserInfoHandler}>
				Save
			</Button>
		</Box>
	</Grid>

}