import {FC} from 'react'
import {useStripeSubscriptionsContext} from '../../../context/StripeSubscriptionsContext'
import {SubscriptionSettings} from './SubscriptionSettings'
import {Grid} from '@mui/material'
import Spinner from '../../spinner/Spinner'

export const SubscriptionSettingsWrapper: FC = () => {
    const {currentSubscription} = useStripeSubscriptionsContext()

    return currentSubscription
        ? <SubscriptionSettings subscription={currentSubscription}/>
        : <Grid container className='licenseSettings'><Spinner/></Grid>
}