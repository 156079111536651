import {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {Location, useLocation, useNavigate} from 'react-router-dom'
import {AppDialog, AppDialogProps} from '../components/appDialog/AppDialog'
import {Action, createBrowserHistory} from 'history'
import {InternalNavigationMap} from '../types/AppNavigation'

export type DialogParams = Omit<AppDialogProps, 'children' | 'open'> & {
    /** Normally, primary action will result in navigating. 
     * This boolean inverts the actions so secondary action will be responsible of navigate*/
    invertedAction?: boolean
}

type BeforeNavigateCallback = () => void
type UnhandledNavigateCallback = (from: Location, to: Location) => void

type AppNavigationContextValue = {
    internalNavigation: Partial<InternalNavigationMap>
    navigate: (route: string, beforeNavigate?: () => void) => void
    internalNavigate: (partialNavigationMap: Partial<InternalNavigationMap>, beforeNavigate?: () => void) => void
    addNavigationDialog: (dialog: DialogParams) => void
    removeNavigationDialog: (dialog: DialogParams) => void
    addBeforeNavigationCallback: (callback: BeforeNavigateCallback) => void
    removeBeforeNavigationCallback: (callback: BeforeNavigateCallback) => void
    addUnhandledNavigationCallback: (callback: UnhandledNavigateCallback) => void
    removeUnhandledNavigationCallback: ((callback: UnhandledNavigateCallback) => void)
}

const DEFAULT_APP_NAVIGATION_CONTEXT_VALUE: AppNavigationContextValue = {
    internalNavigation: {},
    navigate: () => {},
    internalNavigate: () => {},
    addNavigationDialog: () => {},
    removeNavigationDialog: () => {},
    addBeforeNavigationCallback: () => {},
    removeBeforeNavigationCallback: () => {},
    addUnhandledNavigationCallback: () => {},
    removeUnhandledNavigationCallback: () => {}
}

const AppNavigationContext = createContext<AppNavigationContextValue>(DEFAULT_APP_NAVIGATION_CONTEXT_VALUE)

const history = createBrowserHistory()

export const useAppNavigationContext = () => useContext(AppNavigationContext)

export const AppNavigationContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [internalNavigation, setInternalNavigation] = useState<Partial<InternalNavigationMap>>({})
    const [internalNavigationTarget, setInternalNavigationTarget] = useState<Partial<InternalNavigationMap>>()
    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogs, setDialogs] = useState<DialogParams[]>([])
    const [navigateTarget, setNavigateTarget] = useState('')
    const [beforeNavigateCallbacks, setBeforeNavigateCallbacks] = useState<BeforeNavigateCallback[]>([])
    const [unhandledNavigateCallbacks, setUnhandledNavigateCallbacks] = useState<UnhandledNavigateCallback[]>([])

    const navigate = useNavigate()
    const location = useLocation()

    const customNavigate = useCallback((route: string) => {
        if (dialogs.length) {
            setNavigateTarget(route)
            setDialogOpen(true)
        } else {
            beforeNavigateCallbacks.forEach(callback => callback())
            navigate(route)
        }
    },[dialogs, navigate, beforeNavigateCallbacks])

    const customInternalNavigate = useCallback((partialNavigationMap: Partial<InternalNavigationMap>) => {
        if (dialogs.length) {
            setInternalNavigationTarget(partialNavigationMap)
            setDialogOpen(true)
        } else {
            beforeNavigateCallbacks.forEach(callback => callback())
            setInternalNavigation(prev => ({...prev, ...partialNavigationMap}))
        }
    }, [dialogs, beforeNavigateCallbacks])

    const lastDialog = dialogs.length ? dialogs[dialogs.length - 1] : undefined

    const composedDialogParams: DialogParams | undefined = useMemo(() => {
        // Custom dialog params are override with additional functionality
        if (lastDialog) {
            const {primaryText, secondaryText, secondaryClassName, text, title, invertedAction} = lastDialog
            const primaryAction = () => {
                beforeNavigateCallbacks.forEach(callback => callback())
                setDialogOpen(false)
                //Differentiate between react-router-dom navigation and internal navigation
                if (navigateTarget) {
                    navigate(navigateTarget)
                    setNavigateTarget('')
                } else if (internalNavigationTarget) {
                    setInternalNavigation(prev => ({...prev, ...internalNavigationTarget}))
                    setInternalNavigationTarget(undefined)
                }
            }
            const secondaryAction = () => {
                setDialogOpen(false)
            }
            const onPrimary = () => {
                lastDialog.onPrimary()
                invertedAction ? secondaryAction() : primaryAction()
            }
            const onSecondary = lastDialog.onSecondary ? () => {
                lastDialog.onSecondary?.()
                invertedAction ? primaryAction() : secondaryAction()
            } : undefined
            const onClose = () => {
                lastDialog.onClose()
                setDialogOpen(false)
            }

            return {
                primaryText,
                secondaryText,
                secondaryClassName,
                text,
                title,
                onPrimary,
                onSecondary,
                onClose
            } as DialogParams
        } else {
            return undefined
        }
    }, [beforeNavigateCallbacks, lastDialog, navigateTarget, internalNavigationTarget, navigate])

    const addNavigationDialog = useCallback((dialog: DialogParams) => {
        setDialogs(prev => [...prev, dialog])
    }, [])

    const removeNavigationDialog = useCallback((dialog: DialogParams) => {
        setDialogs(prev => prev.filter(item => item !== dialog))
    }, [])

    const addBeforeNavigationCallback = useCallback((callback: BeforeNavigateCallback) => {
        setBeforeNavigateCallbacks(prev => [...prev, callback])
    }, [])

    const removeBeforeNavigationCallback = useCallback((callback: BeforeNavigateCallback) => {
        setBeforeNavigateCallbacks(prev => prev.filter(item => item !== callback))
    }, [])

    const addUnhandledNavigationCallback = useCallback((callback: UnhandledNavigateCallback) => {
        setUnhandledNavigateCallbacks(prev => [...prev, callback])
    }, [])

    const removeUnhandledNavigationCallback = useCallback((callback: UnhandledNavigateCallback) => {
        setUnhandledNavigateCallbacks(prev => prev.filter(item => item !== callback))
    }, [])

    const value: AppNavigationContextValue = useMemo(() => ({
        internalNavigation,
        navigate: customNavigate,
        internalNavigate: customInternalNavigate,
        addNavigationDialog,
        removeNavigationDialog,
        addBeforeNavigationCallback,
        removeBeforeNavigationCallback,
        addUnhandledNavigationCallback,
        removeUnhandledNavigationCallback
    }),[
        internalNavigation,
        customNavigate,
        customInternalNavigate,
        addNavigationDialog,
        removeNavigationDialog,
        addBeforeNavigationCallback,
        removeBeforeNavigationCallback,
        addUnhandledNavigationCallback,
        removeUnhandledNavigationCallback
    ])

    useEffect(() => {
        const unlisten = history.listen((update) => {
            if (update.action === Action.Pop) {
                unhandledNavigateCallbacks.forEach(callback => callback(location, update.location))
                navigate(update.location.pathname)
            }
        })

        return () => {
            unlisten()
        }
    }, [navigate, location, unhandledNavigateCallbacks])
    
    return <AppNavigationContext.Provider value={value}>
        { composedDialogParams ? <AppDialog {...composedDialogParams} open={dialogOpen} /> : <></> }
        {children}
    </AppNavigationContext.Provider>
}