import {PricingInfo, SubscriptionStatus} from '../types/Stripe'

export const VALID_STATUS_SUBSCRIPTIONS: SubscriptionStatus[] = ['trialing', 'active', 'incomplete']
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? ''
export const NARUS_ANALYTICS_APP_NAME = process.env.REACT_APP_NARUS_ANALYTICS_APP_NAME ?? ''
export const GROWTH_AWS_PRICE_ID =  process.env.REACT_APP_STRIPE_PRICE_ID_GROWTH_AWS
export const GROWTH_MONTHLY_PRICE_ID =  process.env.REACT_APP_STRIPE_PRICE_ID_GROWTH_MONTHLY
export const GROWTH_YEARLY_PRICE_ID =  process.env.REACT_APP_STRIPE_PRICE_ID_GROWTH_YEARLY
export const SCALE_AWS_PRICE_ID =  process.env.REACT_APP_STRIPE_PRICE_ID_SCALE_AWS
export const SCALE_MONTHLY_PRICE_ID =  process.env.REACT_APP_STRIPE_PRICE_ID_SCALE_MONTHLY
export const SCALE_YEARLY_PRICE_ID =  process.env.REACT_APP_STRIPE_PRICE_ID_SCALE_YEARLY
export const ULTIMATE_MONTHLY_PRICE_ID = process.env.REACT_APP_STRIPE_PRICE_ID_ULTIMATE_MONTHLY
export const TRIAL_PRICE_ID = process.env.REACT_APP_STRIPE_PRICE_ID_TRIAL
export const PRICES_INFO_MAP: PricingInfo = {
	growth: {
		name: 'Growth',
		benefits: [
			'Prompt optimisation',
			'Prompt libraries',
			'Use multiple AI models',
			'Connect your own APIs',
			'AI cost monitoring',
			'User activity by model',
			'Audit log (3 months history)',
		],
		monthly: {
			priceId: GROWTH_MONTHLY_PRICE_ID,
			priceBrackets: [
				{upperSeatLimit: 100, price: 4.8},
				{upperSeatLimit: 250, price: 3.6},
				{upperSeatLimit: 500, price: 3.0},
				{upperSeatLimit: 1000, price: 2.3976},
				{upperSeatLimit: 2000, price: 1.998},
				{upperSeatLimit: 5000, price: 1.7982},
				{upperSeatLimit: 10000, price: 1.3986},
				{upperSeatLimit: Infinity, price: 1.1988},
			]
		},
		annual: {
			priceId: GROWTH_YEARLY_PRICE_ID,
			priceBrackets: [
				{upperSeatLimit: 100, price: 48},
				{upperSeatLimit: 250, price: 36},
				{upperSeatLimit: 500, price: 30},
				{upperSeatLimit: 1000, price: 23.976},
				{upperSeatLimit: 2000, price: 19.98},
				{upperSeatLimit: 5000, price: 17.982},
				{upperSeatLimit: 10000, price: 13.986},
				{upperSeatLimit: Infinity, price: 11.988},
			]
		},
		maxMonthsAudit: 3
	},
	scale: {
		name: 'Scale',
		benefits: [
			'Prompt optimisation',
			'Prompt libraries',
			'Use multiple AI models',
			'Connect your own APIs',
			'AI cost monitoring',
			'User activity by model',
			'Audit log (3 months history)',
			'Safeguarding',
			'Unlimited LLM\'s',
			'Advanced SLA',
			'Priority support queue',
			'Onboarding package',
			'Dedicated customer success',
		],
		monthly: {
			priceId: SCALE_MONTHLY_PRICE_ID,
			priceBrackets:[
				{upperSeatLimit: 100, price: 7.2},
				{upperSeatLimit: 250, price: 5.4},
				{upperSeatLimit: 500, price: 4.5},
				{upperSeatLimit: 1000, price: 3.6},
				{upperSeatLimit: 2000, price: 3.0},
				{upperSeatLimit: 5000, price: 2.7},
				{upperSeatLimit: 10000, price: 2.1},
				{upperSeatLimit: Infinity, price: 1.8},
			]
		},
		annual: {
			priceId: SCALE_YEARLY_PRICE_ID,
			priceBrackets:[
				{upperSeatLimit: 100, price: 72},
				{upperSeatLimit: 250, price: 54},
				{upperSeatLimit: 500, price: 45},
				{upperSeatLimit: 1000, price: 36},
				{upperSeatLimit: 2000, price: 30},
				{upperSeatLimit: 5000, price: 27},
				{upperSeatLimit: 10000, price: 21},
				{upperSeatLimit: Infinity, price: 18},
			]
		},
		maxMonthsAudit: 6
	},
	ultimate: {
		name: 'Ultimate',
		benefits: [
			'Prompt optimisation',
			'Prompt libraries',
			'Use multiple AI models',
			'Connect your own APIs',
			'AI cost monitoring',
			'User activity by model',
			'Audit log (3 months history)',
			'Safeguarding',
			'Unlimited LLM\'s',
			'Advanced SLA',
			'Priority support queue',
			'Onboarding package',
			'Dedicated customer success',
			'SAML / SSO ',
			'Connect knowledge source',
			'Unlimited audit history',
		],
		monthly: {
			priceId: ULTIMATE_MONTHLY_PRICE_ID,
			priceBrackets:[
				{upperSeatLimit: Infinity, price: 10},
			]
		},
		annual: {
			priceId: ULTIMATE_MONTHLY_PRICE_ID,
			priceBrackets: [
				{upperSeatLimit: Infinity, price: 100},
			]},
		maxMonthsAudit: 0
	},
	trial: {
		name: 'Trial',
		benefits: [
			'Prompt optimisation',
			'Prompt libraries',
			'Use multiple AI models',
			'Connect your own APIs',
			'AI cost monitoring',
			'User activity by model',
			'Audit log (3 months history)',
			'Safeguarding',
			'Unlimited LLM\'s',
			'Advanced SLA',
			'Priority support queue',
			'Onboarding package',
			'Dedicated customer success',
		],
		monthly: {
			priceId: undefined,
			priceBrackets:[
				{upperSeatLimit: Infinity, price: 0},
			]
		},
		annual: {
			priceId: undefined,
			priceBrackets:[
				{upperSeatLimit: Infinity, price: 0},
			]
		},
		maxMonthsAudit: 0
	}
}