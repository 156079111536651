import {FC, useCallback, useEffect, useState} from 'react'
import {
    Box,
    Button,
    Chip,
    DialogContentText,
    Typography,
    Tooltip,
    FormControl,
    FormControlLabel,
    Checkbox
} from '@mui/material'
import './NarusSubscriptionSettings.scss'
import {TrackActionEvent} from '../../../service/SegmentService'
import InfoIcon from '@mui/icons-material/Info'
import {useUser} from '@clerk/clerk-react'
import {PlansDialog} from '../../admin/configuration/settings/PlansDialog'
import {
    getNextPeriodInfo,
    getPeriodByPriceId,
    getPlanByPriceId,
    getPlanPriceId,
    isPeriodUpgrade,
    getPlanAmount, isOnePhaseSchedule, getActiveSubscriptionSchedule, getSubscriptionUpdateModalCopy
} from '../../../utils/licenseUtils'
import {formatDateStringToLocaleDate} from '../../../helpers/DateHelpers'
import ConfirmationDialog from '../../promptForm/confirmationDialog/ConfirmationDialog'
import {PlansConfirmationDialog} from '../../admin/configuration/settings/PlansConfirmationDialog'
import {usePaymentContext} from '../../../context/PaymentContext'
import {SeatsCountInput} from '../pricingTable/SeatsCountInput'
import {useStripeSubscriptionsContext} from '../../../context/StripeSubscriptionsContext'
import {roundTwoDecimals} from '../../../helpers/NumberHelpers'
import {Period, Subscription} from '../../../types/Stripe'
import {useSubscriptionSettingsNavigation} from '../../../hooks/navigation/useSubscriptionSettingsNavigation'
import {isBeforeTargetDate} from '../../../utils/dateUtils'
import {useUserOrganizationContext} from '../../../context/UserOrganizationContext'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'

type Props = {
    subscription: Subscription
}

export const NarusSubscriptionSettings: FC<Props> = ({subscription}) => {
    const {user} = useUser()
    const {userOrganization} = useUserOrganizationContext()

    const {schedules, cancelCurrentSubscription, updateSubscriptionSeats, updateSubscriptionPlan} = useStripeSubscriptionsContext()
    const {
        seats,
        openChangePlanConfirmationDialog,
        openChangePlanDialog,
        setOpenChangePlanDialog,
        setOpenChangePlanConfirmationDialog,
        setPeriodSelected,
        periodSelected
    } = usePaymentContext()

    const plan = getPlanByPriceId(subscription.plan.id)
    const period = getPeriodByPriceId(subscription.plan.id)
    const schedule = getActiveSubscriptionSchedule(subscription, schedules)

    const [showCancelSubscriptionDialog, setShowCancelSubscriptionDialog] = useState(false)
    const [pricePerSeat, setPricePerSeat] = useState(plan && period ? getPlanAmount(plan, period, seats) / seats : 0)
    const [showSubscriptionUpdateDialog, setShowSubscriptionUpdateDialog] = useState(false)

    const isCurrentlySubscriptionCancelled = subscription.cancellation_details?.reason === 'cancellation_requested'
    const licenseExpirationDate = formatDateStringToLocaleDate(subscription.current_period_end * 1000)
    const periodCostTitleMap: { [key in Period]: string } = {
        annual: 'Annual cost',
        monthly: 'Monthly cost'
    }
    const nextPeriodInfo = getNextPeriodInfo(subscription, schedules)
    const saveDisabled = subscription.quantity === seats && !periodSelected
    const subscriptionUpdateModalParams = {
        subscription,
        plan: nextPeriodInfo.plan,
        oldSeats: subscription.quantity,
        updatedSeats: seats,
        currentPeriod: nextPeriodInfo.period,
        nextPeriod: periodSelected
    }

    const onCancelSubscriptionClick = async () => {
        await cancelCurrentSubscription(subscription.id)
        setShowCancelSubscriptionDialog(false)
    }

    const handleSave = useCallback(async () => {
        setShowSubscriptionUpdateDialog(false)
        if (seats !== subscription.quantity) await updateSubscriptionSeats(seats)
        if (periodSelected && nextPeriodInfo.period !== periodSelected) {
            const updatedPlanId = getPlanPriceId(plan, periodSelected)
            const action = isPeriodUpgrade(period, periodSelected) ? 'upgrade' : 'downgrade'
            TrackActionEvent('Billing Cadence', user?.externalId ?? user?.id, {
                from: period,
                to: periodSelected,
                company_id: userOrganization.organizationId
            })
            const nextPeriodPlanId = getPlanPriceId(nextPeriodInfo.plan, nextPeriodInfo.period)
            updateSubscriptionPlan(updatedPlanId, action, nextPeriodPlanId)
        }
    }, [updateSubscriptionSeats, seats, subscription, periodSelected, period, plan, updateSubscriptionPlan, user?.id, user?.externalId, userOrganization.organizationId, nextPeriodInfo])

    useSubscriptionSettingsNavigation(subscription, handleSave)

    const getClassNameAccordingPeriod = (targetPeriod: Period): string =>
        (!schedule && period === targetPeriod) || (!!schedule && !isOnePhaseSchedule(schedule) && nextPeriodInfo.period === targetPeriod) ? 'hidden' : ''

    useEffect(() => {
        setPricePerSeat(getPlanAmount(plan, periodSelected, seats) / seats)
    }, [seats, periodSelected, plan])

    return <Box className='narusSubscriptionDetails'>
        {plan !== 'trial' && <>
            <Typography variant='h3' className='subscriptionDetails'>Subscription details</Typography>
            <Typography className='billingCadence'>Billing cadence</Typography>
            <Box className='billingCadenceButtonsContainer'>
                <FormControl component='fieldset' sx={{gap: 1}}>
                    <Box className='billingCadenceButtonContainer annual'>
                        <FormControlLabel
                            control={<Checkbox className={`periodCheckbox ${getClassNameAccordingPeriod('annual')}`} icon={<RadioButtonUncheckedIcon/>}
                                               onClick={() => setPeriodSelected('annual' as Period)}
                                               checkedIcon={<RadioButtonCheckedIcon className='radioButtonCheckedIcon'/>}
                                               disabled={(!schedule && period === 'annual') || (!!schedule && !isOnePhaseSchedule(schedule) && nextPeriodInfo.period === 'annual')}
                                               checked={periodSelected === 'annual'}/>} label='Annual'
                            className='periodTitle'/>
                        <Chip className='billingCadenceSaveChip' label='Save 17%'/>
                    </Box>
                    <Box className='billingCadenceButtonContainer monthly'>
                        <FormControlLabel
                            control={<Checkbox className={`periodCheckbox ${getClassNameAccordingPeriod('monthly')}`} icon={<RadioButtonUncheckedIcon/>}
                                               onClick={() => setPeriodSelected('monthly' as Period)}
                                               checkedIcon={<RadioButtonCheckedIcon className='radioButtonCheckedIcon'/>}
                                               disabled={(!schedule && period === 'monthly') || (!!schedule && !isOnePhaseSchedule(schedule) && nextPeriodInfo.period === 'monthly')}
                                               checked={periodSelected === 'monthly'}/>} label='Monthly'
                            className='periodTitle'/>
                    </Box>
                </FormControl>
                {!!schedule && !isOnePhaseSchedule(schedule) && (nextPeriodInfo.plan !== plan || nextPeriodInfo.period !== period) && isBeforeTargetDate(subscription.current_period_end) ?
                    <Box>
                        <Chip className='billingCadenceUpdateChip' label='Plan update'/>
                        <Typography className='billingCadenceUpdateInfo'>You have changed your payment
                            from {period} to {nextPeriodInfo.period}. The billing recurrence will be updated along with the next charge.</Typography>
                    </Box> : <></>}
            </Box>
            <Box className='seatsContainer'>
                <SeatsCountInput trackingOrigin='Organization settings'/>
                <Box className='priceSeatsContainer'>
                    <Typography className='pricePerSeat'>${roundTwoDecimals(pricePerSeat)} per seat</Typography>
                    <Typography className='pricePerSeatExplanation'>Price per seat varies according to users added</Typography>
                </Box>
            </Box>
            <Box className='newSeatsContainer'>
                <Typography className='newSeatsAvailability'>
                    <Tooltip
                        title='The new seats added will be available immediately and will be billed retroactively in the next billing cycle for the remainder of the month.'>
                        <InfoIcon className='infoIcon'/>
                    </Tooltip>
                    When will new seats be available?
                </Typography>
            </Box>
            <Box className='periodCostContainer'>
                <Typography className='periodCostTitle'>{periodCostTitleMap[nextPeriodInfo.period]}</Typography>
                <Box className='periodDescriptionContainer'>
                    <Typography className='periodCostAmount'>{nextPeriodInfo.payment} total</Typography>
                    <Typography
                        className='periodCostNextBilling'>{isCurrentlySubscriptionCancelled ? `You have cancelled your subscription to Narus. Cancellation will be active on ${licenseExpirationDate} and users will not have access. ` : `Next charge on ${licenseExpirationDate}`}</Typography>
                </Box>
            </Box>
        </>}

        <PlansDialog open={openChangePlanDialog} onClose={() => setOpenChangePlanDialog(false)}/>
        <PlansConfirmationDialog open={openChangePlanConfirmationDialog} plan={plan}
                                 onClose={() => setOpenChangePlanConfirmationDialog(false)}/>
        <Box className='actionsContainer'>
            {!isCurrentlySubscriptionCancelled && <Box className='actionsWrapper'>
                <Button variant='outlined' color='error' onClick={() => setShowCancelSubscriptionDialog(true)}>Cancel
                    subscription</Button>
                <Button variant='contained' className='saveSubscriptionButton' onClick={() => setShowSubscriptionUpdateDialog(true)} disabled={saveDisabled}>Save</Button>
            </Box>
            }

            <ConfirmationDialog handleClose={() => setShowCancelSubscriptionDialog(false)}
                                open={showCancelSubscriptionDialog}
                                handleDelete={onCancelSubscriptionClick}
                                currentEvent='cancelSubscription'>
                <DialogContentText>Narus users in your organization will still have access until the next payment
                    cycle on {licenseExpirationDate}</DialogContentText>
            </ConfirmationDialog>
            <ConfirmationDialog handleClose={() => setShowSubscriptionUpdateDialog(false)}
                                open={showSubscriptionUpdateDialog}
                                handleConfirmAction={handleSave}
                                currentEvent='changeSubscription'>
                <DialogContentText>{getSubscriptionUpdateModalCopy(subscriptionUpdateModalParams)}</DialogContentText>
            </ConfirmationDialog>
        </Box>
    </Box>
}