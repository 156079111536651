import {Box, Button, Divider, Drawer, Grid, Tab, Tabs, Typography} from '@mui/material'
import {ChangeEvent, useCallback, useEffect, useState} from 'react'
import {CustomTabPanel} from '../../../homeTabs/HomeTabs'
import {GroupTable} from './GroupTable'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {GroupsListMenu} from './GroupsMenuList'
import {EmptyGroups} from '../../../emptySection/EmptyGroups'
import {useGroupsContext} from '../../../../context/GroupsContext'
import {GroupCreation} from './GroupCreation'
import {EditGroupMenu} from './EditGroupMenu'
import {GroupAddUsers} from './GroupAddUsers'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {Group} from '../../../../types/Group'
import {getSortedGroupNames, isUserInSelectedGroup} from '../../../../utils/groupUtils'
import {hasProp, isEqual} from '../../../../utils/genericUtils'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import './Groups.scss'
import {GroupSettings} from './GroupSettings'

export const Groups = () => {
    const {user} = useUser()
    const {toggleDrawer, displayDrawerCreateGroup} = useToggleDrawerContext()
    const {
        groups,
        selectedGroup,
        setSelectedGroup,
        allGroupsMembers,
        groupMembers
    } = useGroupsContext()
    const [searchText, setSearchText] = useState('')
    const [selectedTab, setSelectedTab] = useState(0)
    const [addUsersOpen, setAddUsersOpen] = useState(false)
    const [filteredGroups, setFilteredGroups] = useState<Group[]>([])

    const selectTabHandler = (newValue: number) => {
        setSelectedTab(newValue)
    }

    const handleAddUsersClick = useCallback(() => {
        setAddUsersOpen(true)
        TrackActionEvent('Groups', user?.externalId ?? user?.id, {action: 'edit', groupId: selectedGroup?.hashKey})
    }, [user?.id, user?.externalId, selectedGroup?.hashKey])

    const handleAddUsersClose = useCallback(() => {
        setAddUsersOpen(false)
        TrackActionEvent('Groups', user?.externalId ?? user?.id, {action: 'done', groupId: selectedGroup?.hashKey})
    }, [user?.id, user?.externalId, selectedGroup?.hashKey])

    const getSortedFilteredGroups = useCallback((searchText: string): Group[] => {
        const filteredGroups = groups
            .filter(group => (
                (searchText.length && (group.name.toLowerCase().includes(searchText) || isUserInSelectedGroup(allGroupsMembers, group.hashKey, searchText))))
            )
        return filteredGroups.length > 1 ? getSortedGroupNames(filteredGroups) : filteredGroups
    }, [groups, allGroupsMembers])

    const handleSearchTextChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value.trim().toLowerCase())
    }, [])

    useEffect(() => {
        setSelectedGroup(prevGroup => {
            if (filteredGroups.length) {
                return filteredGroups.some(hasProp('hashKey', prevGroup?.hashKey)) ? prevGroup : filteredGroups[0]
            } else {
                return undefined
            }
        })
    }, [filteredGroups, setSelectedGroup])

    useEffect(() => {
        setFilteredGroups(prevGroups => {
            if (searchText.trim()) {
                const filteredGroups = getSortedFilteredGroups(searchText)
                return isEqual(filteredGroups, prevGroups) ? prevGroups : filteredGroups
            } else {
                return groups
            }
        })
    }, [searchText, getSortedFilteredGroups, groups])

    const notFoundGroups = (!filteredGroups.length) && (searchText.length > 0)

    return <>
        <Box className='teamsContainer'>
            <Typography className='teamsTitle'>Teams</Typography>
            <Typography className='teamsSubtitle'>Use teams to easily manage and monitor AI usage.</Typography>
            <Grid container className='teamsContent'>
                <Grid xs={2} item flexDirection='column' className='teamsSearchAndListContainer'>
                    <Box className='teamsSearchBar'>
                        <Box className='teamsSearchIconContainer'>
                            <SearchIcon/>
                        </Box>
                        <InputBase
                            className='teamsSearchInput'
                            placeholder='Search…'
                            inputProps={{'aria-label': 'search'}}
                            onChange={handleSearchTextChange}
                        />
                    </Box>
                    <Button className='newTeamButton' startIcon={<AddIcon/>}
                            onClick={toggleDrawer(true, 'CREATE_GROUP_DRAWER')}>New team</Button>
                    <GroupsListMenu groups={filteredGroups}/>
                </Grid>
                {groups.length ? <Grid xs={10} item>
                    <Box className='teamTableHeaderContainer'>
                        <Box className='teamTableHeader'>
                            <Typography className='teamTableTitle'
                                        variant='h3'>{notFoundGroups ? '' : selectedGroup?.name}</Typography>
                            <Box className='teamTableButtonsContainer'>
                                <Button startIcon={<AddIcon/>} variant='contained' className='addUsersButton' disabled={!selectedGroup} onClick={handleAddUsersClick}>Add Users</Button>
                                <Divider/>
                                <EditGroupMenu group={selectedGroup}/>
                            </Box>
                        </Box>
                        <Grid xs={12} item>
                            <Tabs value={selectedTab}
                                  onChange={(_, newValue) => selectTabHandler(newValue)}
                                  className='teamTabContainer' variant='scrollable'>
                                <Tab className='teamTabContainerItem' label='Members'/>
                                <Tab className='teamTabContainerItem' label='Settings'/>
                            </Tabs>
                        </Grid>
                    </Box>
                    <Box>
                        <CustomTabPanel value={selectedTab} index={0}>
                            <GroupTable groupMembers={notFoundGroups ? [] : groupMembers}/>
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTab} index={1}>
                            <GroupSettings/>
                        </CustomTabPanel>
                    </Box>
                </Grid> : <EmptyGroups/>}
            </Grid>
        </Box>
        <Drawer anchor='right' open={displayDrawerCreateGroup}
                onClose={toggleDrawer(false, 'CREATE_GROUP_DRAWER')}>
            <GroupCreation/>
        </Drawer>
        <GroupAddUsers open={addUsersOpen} onClose={handleAddUsersClose}/>
        
    </>
}