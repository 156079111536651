import {useEffect} from 'react'
import {Box, Typography} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import './NoSeatsError.scss'
import {PageView} from '../../service/SegmentService'
import {RoundIcon} from '../common/roundIcon/RoundIcon'
import {InfoScreen} from '../common/infoScreen/InfoScreen'

export const NoSeatsError = () => {

	useEffect(() => {
		PageView('No seats error')
	}, [])

	return (
		<InfoScreen>
			<Box className='noSeatsErrorContainer'>
				<RoundIcon className='noSeatsErrorIcon' icon={ErrorIcon} size={200} iconSize={150} colorEnd='#CA97A280' colorStart='#C1AFE580' iconColorEnd='#C1AFE580'
				           iconColorStart='#CA97A280'/>
				<Typography className='noSeatsErrorTitle'>Oops! Looks like this organization is full.</Typography>
				<Typography className='noSeatsErrorSubtitle'>There are no seats available in this organization. Ask your administrator to increase Nº of seats in the license and
					billing management section.</Typography>
			</Box>
		</InfoScreen>
	)
}