import { useUser } from '@clerk/clerk-react'
import { Box, Typography } from '@mui/material'
import { USER_DOCUMENTATION_URL } from '../../constants/Links'
import { TrackActionEvent } from '../../service/SegmentService'
import HelpIcon from '@mui/icons-material/Help'

export const AccountCreationSideHelp = () => {
    const {user} = useUser()

    const handleHelpClicked = () => {
        TrackActionEvent('Create Organization Onboarding', user?.id, {action: 'help'})
        window.open(USER_DOCUMENTATION_URL, 'narusHelp')
    }
    
    return (
        <Box className='AccountCreation_SideHelp' onClick={handleHelpClicked}>
            <HelpIcon color='info'/>
            <Typography className='AccountCreation_SideHelpText'>
                Need help with anything?
            </Typography>
        </Box>
    )
}