import {FC, PropsWithChildren} from 'react'
import {PromptsContextProvider} from '../context/PromptsContext'
import {UserGroupContextProvider} from '../context/UserGroupContext'
import {SearchContextProvider} from '../context/SearchContext'
import {ChatMessagesContextProvider} from '../context/ChatMessagesContext'
import {PromptCreationContextProvider} from '../context/PromptCreationContext'
import {ChatsContextProvider} from '../context/ChatsContext'
import {UserBudgetContextProvider} from '../context/UserBudgetContext'
import {FilesContextProvider} from '../context/FilesContext'

export const UserProviders: FC<PropsWithChildren> = ({children}) => (
	<FilesContextProvider>
		<PromptsContextProvider>
			<UserGroupContextProvider>
				<SearchContextProvider>
					<ChatMessagesContextProvider>
						<PromptCreationContextProvider>
							<ChatsContextProvider>
								<UserBudgetContextProvider>
									{children}
								</UserBudgetContextProvider>
							</ChatsContextProvider>
						</PromptCreationContextProvider>
					</ChatMessagesContextProvider>
				</SearchContextProvider>
			</UserGroupContextProvider>
		</PromptsContextProvider>
	</FilesContextProvider>
)