import {
    Alert,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    InputAdornment, Paper,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography
} from '@mui/material'
import {DatePicker} from 'rsuite'
import EventIcon from '@mui/icons-material/Event'
import React, {ChangeEvent, useState} from 'react'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {Budget, BudgetPeriodicityCustomType, BudgetPeriodicityType} from '../../../../types/Budget'
import {useUser} from '@clerk/clerk-react'
import {useBudgetsContext} from '../../../../context/BudgetContext'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {endOfDay} from 'rsuite/cjs/utils/dateUtils'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import './BudgetForm.scss'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import {capitalizeFirstLetter} from '../../../../utils/textUtils'
import {getPeriodicityInDays} from '../../../../utils/budgetUtils'
import Select from 'react-select'

export const BudgetForm = () => {
    const {toggleDrawer} = useToggleDrawerContext()
    const {user} = useUser()
    const {saveBudget} = useBudgetsContext()

    const [budgetNeverEnds, setBudgetNeverEnds] = useState<boolean>(true)
    const [startDate, setStartDate] = useState<Date>()
    const [endDate, setEndDate] = useState<Date>()
    const [amount, setAmount] = useState<string>()
    const [periodicityType, setPeriodicityType] = useState<BudgetPeriodicityType>('Monthly')
    const [amountValidationError, setAmountValidationError] = useState<boolean>(false)
    const [timeUnit, setTimeUnit] = useState<string>('')
    const [periodicityNumber, setPeriodicityNumber] = useState<number>()

    const onOpenCalendarHandler = () => {
        TrackActionEvent('Budget calendar', user?.externalId ?? user?.id, {action: 'open'})
    }

    const onCleanHandler = () => {
        TrackActionEvent('Budget calendar', user?.externalId ?? user?.id, {action: 'clean'})
    }

    const onStartDateHandler = (date: Date | null) => {
        TrackActionEvent('Budget', user?.externalId ?? user?.id, {action: 'set_start_date'})
        setStartDate(date ?? undefined)
        setEndDate(undefined)
    }

    const onEndDateHandler = (date: Date | null) => {
        TrackActionEvent('Budget', user?.externalId ?? user?.id, {action: 'set_end_date'})
        if (date) setEndDate(date)
    }

    const onEndDateOptionHandler = (_, value: string) => {
        const isBudgetNeverEnds = value === 'never'
        if (isBudgetNeverEnds) setEndDate(undefined)
        setBudgetNeverEnds(isBudgetNeverEnds)
    }

    const onSaveBudgetHandler = async (event: React.MouseEvent) => {
        const invalidEndDate = (!endDate && !budgetNeverEnds)
        const parsedAmount = parseFloat(amount ?? '')
        const isAmountInvalid = isNaN(parsedAmount) || parsedAmount <= 0
        const isCustomLimitInvalid = periodicityType === 'Custom' && (!timeUnit || !periodicityNumber)
        if (isAmountInvalid) setAmountValidationError(true)
        if (!startDate || invalidEndDate || isAmountInvalid || isCustomLimitInvalid) return

        const budget: Partial<Budget> = {
            periodicityInDays: getPeriodicityInDays({
                type: periodicityType,
                customType: timeUnit as BudgetPeriodicityCustomType,
                unit: periodicityNumber ?? 1
            }),
            amount: parsedAmount,
            startDate: startDate?.getTime(),
            periodStartDate: startDate?.getTime(),
            endDate: endDate?.getTime() ?? new Date('9999-12-31').getTime(),
            periodicityType
        }
        const createdBudget = await saveBudget(budget)
        if (createdBudget) {
            TrackActionEvent('Budget', user?.externalId ?? user?.id, {action: 'save', periodicity: periodicityType})
            toggleDrawer(false, 'EDIT_OVERALL_BUDGET')(event)
        }
    }

    const handlePeriodicity = (value: string) => {
        setPeriodicityType(capitalizeFirstLetter(value) as BudgetPeriodicityType)
    }

    const onAmountHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setAmountValidationError(false)
        setAmount(event.target.value)
    }

    return <>
        <Box className='BudgetForm'>
            <Typography variant="h6" className='BudgetForm_Subtitle'>
                Spending limit
            </Typography>
            <Box>
                <Typography variant="body2" className='BudgetForm_AmountLabel'>Amount</Typography>
                <TextField
                    fullWidth
                    placeholder="Eg. 20,000"
                    value={amount}
                    onChange={onAmountHandler}
                    error={amountValidationError}
                    helperText={amountValidationError ? 'Amount must be a number greater than 0' : ''}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">$</InputAdornment>,
                    }}
                    className='BudgetForm_TextField'
                />
                <Alert
                    severity="info"
                    icon={<InfoIcon fontSize="small"/>}
                    className='BudgetForm_InfoAlert'
                >
                    <Typography>Costs are estimated. Actual bill may vary.</Typography>
                </Alert>
            </Box>
        </Box>

        <Box className='BudgetForm_CycleContainer'>
            <Typography variant="h6" className='BudgetForm_Subtitle'>
                Spending limit cycle
            </Typography>
            <Typography variant="body2" className='BudgetForm_Description'>
                Choose how often your spending limit resets
            </Typography>
            <RadioGroup
                row
                defaultValue={'monthly'}
                onChange={(_, value) => handlePeriodicity(value)}
                className='BudgetForm_RadioGroup'
            >
                <Paper variant="outlined" className='BudgetForm_Radio cycle'>
                    <FormControlLabel
                        value="monthly"
                        control={<Radio checkedIcon={<RadioButtonCheckedIcon className='radioButtonCheckedIcon'/>}/>}
                        label="Monthly"
                    />
                </Paper>
                <Paper variant="outlined" className='BudgetForm_Radio cycle'>
                    <FormControlLabel
                        value="quarterly"
                        control={<Radio checkedIcon={<RadioButtonCheckedIcon className='radioButtonCheckedIcon'/>}/>}
                        label="Quarterly"
                    />
                </Paper>
                <Paper variant="outlined" className='BudgetForm_Radio cycle'>
                    <FormControlLabel
                        value="custom"
                        control={<Radio checkedIcon={<RadioButtonCheckedIcon className='radioButtonCheckedIcon'/>}/>}
                        label="Custom"
                    />
                </Paper>
            </RadioGroup>

            {periodicityType === 'Custom' && (
                <Stack direction='row' spacing={2}>
                    <Box className='BudgetForm_TimeUnit'>
                        <Typography variant='body2' gutterBottom>
                            Time unit
                        </Typography>
                        <FormControl fullWidth component='div' className='BudgetForm_FormControl'>
                            <Select required className='BudgetForm_Creatable' placeholder='Select a time unit'
                                    options={['Days', 'Weeks', 'Months'].map(unit => ({value: unit, label: unit}))}
                                    value={timeUnit ? {label: timeUnit, value: timeUnit} : null}
                                    onChange={(event) => setTimeUnit(event.values()?.next()?.value?.value)}
                                    styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                                    menuPortalTarget={document.body} isMulti
                                    isOptionDisabled={() => !!timeUnit} components={{IndicatorSeparator: () => <></>}}
                                    isClearable={false}
                            />
                        </FormControl>
                    </Box>
                    <Box className='BudgetForm_PeriodNumber'>
                        <Typography variant="body2" gutterBottom>
                            Number
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={periodicityNumber}
                            onChange={(e) => setPeriodicityNumber(Number(e.target.value))}
                            size='small'
                            type='text'
                            inputProps={{min: 1}}
                        />
                    </Box>
                </Stack>
            )}
        </Box>

        <Box className={`BudgetForm_DatesContainer ${periodicityType === 'Custom' ? 'customPadding' : ''}`}>
            <Typography variant="h6" className='BudgetForm_Subtitle'>
                Effective Dates
            </Typography>
            <Typography variant="body2" className='BudgetForm_Description'>
                Choose the date range over which the spending limit cycles will be applied.
            </Typography>
            <Typography variant="body2" className='BudgetForm_DateLabel'>Date</Typography>
            <Box className='BudgetForm_DateRadioFields'>
                <Box className='BudgetForm_DateFields'>
                    <DatePicker
                        oneTap
                        className='BudgetForm_DatePicker'
                        placeholder="YYYY/MM/DD"
                        caretAs={EventIcon}
                        format='yyyy/MM/dd'
                        shouldDisableDate={(date: Date) => date < new Date()}
                        onChange={onStartDateHandler}
                        onOpen={onOpenCalendarHandler}
                        onClean={onCleanHandler}
                        placement='topEnd'
                    />
                    <DatePicker
                        oneTap
                        className='BudgetForm_DatePicker'
                        value={endDate}
                        placeholder="YYYY/MM/DD"
                        caretAs={EventIcon}
                        disabled={budgetNeverEnds}
                        format='yyyy/MM/dd'
                        shouldDisableDate={(date: Date) => date <= (endOfDay(startDate || new Date()))}
                        onChange={onEndDateHandler}
                        onOpen={onOpenCalendarHandler}
                        onClean={onCleanHandler}
                        placement='topEnd'
                    />
                </Box>
                <FormControl component='div'>
                    <RadioGroup
                        defaultValue={'never'}
                        onChange={onEndDateOptionHandler}
                        className='BudgetForm_DateRadioGroup'
                    >
                        <Paper variant="outlined" className='BudgetForm_Radio limit'>
                            <FormControlLabel
                                value='never'
                                control={<Radio
                                    checkedIcon={<RadioButtonCheckedIcon className='radioButtonCheckedIcon'/>}/>}
                                label='Never ends'
                            />
                        </Paper>
                        <Paper variant='outlined' className='BudgetForm_Radio limit'>
                            <FormControlLabel
                                value='on'
                                control={<Radio
                                    checkedIcon={<RadioButtonCheckedIcon className='radioButtonCheckedIcon'/>}/>}
                                label='Ends on'
                            />
                        </Paper>
                    </RadioGroup>
                </FormControl>
            </Box>
        </Box>
        <Box className='BudgetForm_Buttons'>
            <Button variant='outlined' onClick={toggleDrawer(false, 'EDIT_OVERALL_BUDGET')}>Cancel</Button>
            <Button variant='contained' onClick={onSaveBudgetHandler}>Set organisation limit</Button>
        </Box>
    </>
}