import {ChangePlanActionsInfo} from '../types/Stripe'
import {PaymentInfoStored} from '../components/license/payment/PaymentInfoStored'
import {SubscriptionSettingsWrapper} from '../components/license/subscriptionSettings/SubscriptionSettingsWrapper'
import {InternalNavigationMap} from '../types/AppNavigation'

export const CHANGE_PLAN_ACTIONS_INFO: ChangePlanActionsInfo = {
    upgrade: {
        card1: {
            title: 'More API connections',
            description: 'You will have unlimited API connections.'
        },
        card2: {
            title: 'New features',
            description: 'You will have access to new features.'
        },
        card3: {
            title: 'More protection',
            description: 'You can better control potential risks.'
        },
        card4: {
            title: 'Audit history',
            description: 'You will now have access to the audit history.'
        }
    },
    downgrade: {
        card1: {
            title: 'Less API connections',
            description: 'You will have limited API connections.'
        },
        card2: {
            title: 'Less features',
            description: 'You will no longer have access to these features.'
        },
        card3: {
            title: 'Less protection',
            description: 'You can no longer control potential risks.'
        },
        card4: {
            title: 'No Audit history',
            description: 'You will lose access to audit history.'
        }
    }
}

export const PLAN_HIERARCHY = ['trial', 'growth', 'scale', 'ultimate'] as const

export const planFeatureValues = {
    trial: {
        api: 'Unlimited',
        audit: 'Unlimited',
    },
    growth: {
        api: '2',
        audit: '3 months'
    },
    scale: {
        api: 'Unlimited',
        audit: '6 months'
    },
    ultimate: {
        api: 'Unlimited',
        audit: 'Unlimited'
    }
}

export const LICENSE_COMPONENTS_MAP: Record<InternalNavigationMap['licenseSettings'], () => JSX.Element> = {
    'subscription': () => <SubscriptionSettingsWrapper/>,
    'payment': () => <PaymentInfoStored/>,
    'invoice': () => <></> // TODO to be developed in other task
}