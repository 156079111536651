import {
    Box, Button,
    Divider, FormControl,
    Grid, InputLabel,
    Select,
    Stack, TextField,
    Typography
} from '@mui/material'
import Spinner from '../../spinner/Spinner'
import './PaymentInfoStored.scss'
import {usePaymentContext} from '../../../context/PaymentContext'
import {ReactComponent as VisaIcon} from '../../../images/visa-logo.svg'
import {capitalizeFirstLetter} from '../../../utils/textUtils'
import {useStripeSubscriptionsContext} from '../../../context/StripeSubscriptionsContext'
import {isCardExpired} from '../../../utils/licenseUtils'

export const PaymentInfoStored = () => {

    const {customerBillingInfo} = usePaymentContext()
    const {currentSubscription} = useStripeSubscriptionsContext()

    if (!currentSubscription) return <Grid item ml={4}><Spinner/></Grid>

    const cardInfo = customerBillingInfo?.card ?
        `${capitalizeFirstLetter(customerBillingInfo.card.brand)} ending ${customerBillingInfo.card.last4}` :
        'Looks like you have not added a payment method. Add a payment method to keep using Narus after your license expires.'
    const cardExpiration = customerBillingInfo?.card ? `${customerBillingInfo.card.exp_month}/${customerBillingInfo.card.exp_year}` : ''

    return <Grid item xs={10} className='paymentInfoContainer'>
        <Typography variant='h3' className='paymentInfoTitle'>Payment method</Typography>
        <Divider/>
        <Stack className='paymentCardInfoContainer'>
            <Box display='flex' alignItems='center'>
                <Box className='paymentCardInfoLogo'>{customerBillingInfo?.card?.brand === 'visa' ? <VisaIcon/> : <></>}</Box> {/* waiting UX for card logos assets */}
                <Typography variant='body1' className={`${!customerBillingInfo?.card ? 'noCard' : ''} 'paymentCardDigits'`}>{cardInfo}</Typography>
                <Typography variant='body1' className={`${!customerBillingInfo?.card ? 'noCard' : ''} 'paymentCardExpiration'`}>{cardExpiration}</Typography>
            </Box>
            <Button variant='outlined'>Update</Button>
        </Stack>
        {customerBillingInfo?.card && isCardExpired(customerBillingInfo.card) ?
            <Typography variant='body1' className='paymentCardExpirationInfo'>Looks like your card has been expired. Update the
                payment method information to keep using Narus after your license expires. </Typography> : <></>}
        <Typography variant='h3' className='paymentInfoTitle'>Invoice details</Typography>
        <Divider/>
        {customerBillingInfo?.address ?
            <Box display='flex' flexDirection='column' className='paymentInfoFormContainer'>
                <Box component='form' className='paymentInfoForm'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label='Invoice email' required value={customerBillingInfo.email}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label='Name on invoice' value={customerBillingInfo.name}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label='Address'
                                       value={`${customerBillingInfo.address.line1} ${customerBillingInfo.address.line2 ?? ''}`}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label='Postcode' value={customerBillingInfo.address.postal_code}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label='City' value={customerBillingInfo.address.city}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id='country-select-label'>Country</InputLabel>
                                <Select
                                    labelId='country-select-label'
                                    label='Country'
                                    value={customerBillingInfo.address.country}
                                >
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box className='savePaymentInfoButtonContainer' mt={3}>
                    <Button variant='contained' disabled>Save</Button>
                </Box>
            </Box> : <Box className='noBillingInfo'>
                <Typography variant='body1' className='noBillingInfoText'>Looks like you have not added your billing information.</Typography>
            </Box>}
    </Grid>
}