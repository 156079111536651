import {Box, Button, Typography} from '@mui/material'
import {AWS_MARKETPLACE_URL} from '../../../constants/Links'
import './AWSSubscriptionSettings.scss'

export const AWSSubscriptionSettings = () => {
    return (
        <Box className='awsSubscriptionDetails'>
            <Typography variant='h3' className='subscriptionDetails'>Subscription details</Typography>
            <Typography className='awsSubscriptionDetailsSubtext'>Your subscription, license, and billing information is all managed through your AWS Marketplace account.</Typography>
            <Button variant='contained' className='goToAWSButton' onClick={() => window.open(AWS_MARKETPLACE_URL, '_blank', 'noreferrer')}>Go to AWS Marketplace</Button>
        </Box>
    )
}