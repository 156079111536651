import {FC, PropsWithChildren, useEffect} from 'react'
import {useStripeSubscriptionsContext} from '../../context/StripeSubscriptionsContext'
import {TrialBanner} from './TrialBanner'
import {useNavigate} from 'react-router-dom'

export const TrialBoundary: FC<PropsWithChildren> = ({
    children
}) => {
    const {isActiveSubscription} = useStripeSubscriptionsContext()
    const navigate = useNavigate()

    useEffect(() => {
        if (!isActiveSubscription)
            navigate('/license-expired')
    }, [isActiveSubscription, navigate])

    return <>
        <TrialBanner/>
        {children}
    </>
}