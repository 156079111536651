import {createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext} from 'react'
import {UsageStatistic, UserUsageStatistic} from '../types/UserUsageStatistic'
import {AIModelCost, StatisticByModel, useStatistics} from '../hooks/useStatistics'
import {DateRange} from 'rsuite/DateRangePicker'

export interface StatisticsContextValue {
	loading: boolean
	usageStatistics: UsageStatistic | undefined
	top5UsersStatistics: UserUsageStatistic[]
	totalPromptsByModel: StatisticByModel[]
	totalUsersByModel: StatisticByModel[]
	topModelsByCost: AIModelCost[]
	dateRangeFilter: DateRange | undefined
	setDateRangeFilter: Dispatch<SetStateAction<DateRange | undefined>>
}

const DEFAULT_STATISTIC_CONTEXT: StatisticsContextValue = {
	loading: false,
	usageStatistics: undefined,
	top5UsersStatistics: [],
	totalPromptsByModel: [],
	totalUsersByModel: [],
	topModelsByCost: [],
	dateRangeFilter: undefined,
	setDateRangeFilter: () => {}
}

export const StatisticContext = createContext<StatisticsContextValue>(DEFAULT_STATISTIC_CONTEXT)

export const useStatisticsContext = () => useContext(StatisticContext)

export const StatisticsContextProvider: FC<PropsWithChildren> = ({children}) => {
	const value = useStatistics()

	return <StatisticContext.Provider value={value}>
		{children}
	</StatisticContext.Provider>
}