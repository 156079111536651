import {ReactNode, useEffect} from 'react'
import {useAuth, useUser} from '@clerk/clerk-react'
import {isGenericDomainEmail} from '../../utils/accountCreationHelpers'
import {deleteUser} from '../../service/persistenceService'
import {useNavigate} from 'react-router-dom'

interface EmailValidatorProps {
    children: ReactNode
}

export const EmailValidator = ({children}: EmailValidatorProps) => {
    const {user} = useUser()
    const {getToken} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        const checkEmailRestriction = async () => {
            const email = user?.emailAddresses[0]?.emailAddress
            if (user && email && isGenericDomainEmail(email)) {
                const token = await getToken({template: process.env.REACT_APP_CLERK_TEMPLATE_NAME}) ?? ''
                deleteUser(token, user.id, email)
                navigate('/sign-up-error')
            }
        }

        checkEmailRestriction()
    }, [user, getToken, navigate])

    return <>{children}</>
}