import {Grid} from '@mui/material'
import './LicenseSetting.scss'
import {BillingTabs} from '../../../license/billingTabs/BillingTabs'
import {LICENSE_COMPONENTS_MAP} from '../../../../constants/LicenseConstants'
import {useAppNavigationContext} from '../../../../context/AppNavigationContext'
import {InternalNavigationMap} from '../../../../types/AppNavigation'

export const LicenseSettings = () => {

    const {internalNavigation} = useAppNavigationContext()
    
    const billingTabView: InternalNavigationMap['licenseSettings'] = internalNavigation['licenseSettings'] ?? 'subscription'

    const LicenseComponent = LICENSE_COMPONENTS_MAP[billingTabView]

    return <Grid container className='licenseSettings'>
        <BillingTabs/>
        <LicenseComponent/>
    </Grid>
}