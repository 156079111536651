import {FC, useEffect} from 'react'
import {Box} from '@mui/material'
import {AuditingLogTrail} from './AuditingLogTrail'
import {AuditingLogDetailHeader} from './AuditingLogDetailHeader'
import {AuditingLogDetailInfo} from './AuditingLogDetailInfo'
import {AuditingLogDetailList} from './AuditingLogDetailList'
import './AuditingLogDetail.scss'
import {TrackActionEvent} from '../../../service/SegmentService'
import {useNavigate, useParams} from 'react-router-dom'
import {useUser} from '@clerk/clerk-react'
import {useAuditingLogsContext} from '../../../context/AuditingLogsContext'

export const AuditingLogDetail: FC = () => {

	const navigate = useNavigate()
	const {logId} = useParams()
	const {user} = useUser()
	const {viewAuditingLog, auditingLogs} = useAuditingLogsContext()

	const auditingLog = auditingLogs.find(log => log.hashKey === logId)

	const goBack = () => {
		TrackActionEvent('Audit log details', user?.externalId ?? user?.id, {action: 'go_back'})
		navigate(-1)
	}

	useEffect(() => {
		if (auditingLog && !auditingLog.isViewed) {
			viewAuditingLog(auditingLog)
		}
	}, [viewAuditingLog, auditingLog])

	return <Box className='auditingLogDetail'>
		<Box className='auditingLogDetailContainer'>
			<AuditingLogDetailHeader onBack={goBack}/>
		</Box>
		{auditingLog && <>
            <Box className='auditingLogDetailContainer auditDataRow'>
                <AuditingLogDetailInfo auditLog={auditingLog}/>
            </Box>
            <Box className='auditingLogsContentContainer'>
                <Box className='auditingLogDetailContainer auditLogsContainer'>
                    <AuditingLogDetailList auditLog={auditingLog}/>
                </Box>
                <Box className='auditingLogDetailContainer auditTrailContainer'>
                    <AuditingLogTrail auditLog={auditingLog}/>
                </Box>
            </Box>
        </>
		}
	</Box>
}
