import { Box, Button, Typography } from '@mui/material'
import { FC } from 'react'
import AdminSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import CableIcon from '@mui/icons-material/Cable'
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import './AccountCreationWorkspaceCreated.scss'
import { useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import { TrackActionEvent } from '../../service/SegmentService';

type Props = {
    onContinue: () => void
}

export const AccountCreationWorkspaceCreated: FC<Props> = ({
    onContinue
}) => {
    const { user } = useUser()
    const navigate = useNavigate()

    const handleLeave = () => {
        TrackActionEvent('Workspace Created', user?.id, { action: 'leave' })
        navigate('/')
    }

    const handleContinue = () => {
        TrackActionEvent('Workspace Created', user?.id, { action: 'continue' })
        onContinue()
    }

    return (
        <Box className='AccountCreation_Main AccountCreationWorkspace_Content'>
            <CheckCircleIcon className='AccountCreationWorkspace_Icon'/>
            <Box className='AccountCreationWorkspace_TitleContainer'>
                <Typography className='AccountCreationWorkspace_Title'>
                    Your workspace has been created
                </Typography>
                <Typography className='AccountCreationWorkspace_Text'>
                    There are two more steps needed to fully configure your workspace. You can complete these steps now or skip them and start exporing Narus.
                </Typography>
            </Box>
            <Box className='AccountCreationWorkspace_List'>
                <Box className='AccountCreationWorkspace_ListItem'>
                    <AdminSettingsIcon className='AccountCreationWorkspace_ListIcon'/>
                    <Typography className='AccountCreationWorkspace_ListText'>Assign admin permissions</Typography>
                </Box>
                <Box className='AccountCreationWorkspace_ListItem'>
                    <CableIcon className='AccountCreationWorkspace_ListIcon'/>
                    <Typography className='AccountCreationWorkspace_ListText'>Manage initial access to AI models</Typography>
                </Box>
            </Box>
            <Box className='AccountCreationWorkspace_Actions'>
                <Button className='AccountCreationWorkspace_Action' variant='outlined' size='large' onClick={handleLeave}>Set up later</Button>
                <Button className='AccountCreationWorkspace_Action' variant='contained' size='large' onClick={handleContinue}>Continue</Button>
            </Box>
        </Box>
    )
}