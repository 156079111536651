import {useCallback, useEffect, useMemo, useState} from 'react'
import {UsageStatistic, UserUsageStatistic} from '../types/UserUsageStatistic'
import {AIModel, AIModelID, AIModels} from '../types/AiModel'
import {roundTwoDecimals} from '../helpers/NumberHelpers'
import {useUsersAccessContext} from '../context/UsersAccessContext'
import {UserInfo} from '../types/UserInfo'
import {useAiModelsContext} from '../context/AIModelsContext'
import {StatisticsContextValue} from '../context/StatisticsContext'
import {BUDGET_CHART_DISPLAYED_MODELS} from '../constants/BudgetConstants'
import {useAiPricingContext} from '../context/AIPricingContext'
import {useAuditingLogsContext} from '../context/AuditingLogsContext'
import {useGroupsContext} from '../context/GroupsContext'
import {getUserUsageStatistics} from '../utils/statisticUtils'
import {GroupMember} from '../types/GroupMember'
import {DateRange} from 'rsuite/DateRangePicker'
import {isAuditInfoInDateRange} from '../utils/auditInfoUtils'
import {getAllDayDateRange} from '../utils/dateUtils'

export type StatisticByModel = {
	name: string
	value: number
}

export type AIModelCost = {
	modelName: string
	modelId: AIModelID | string
	cost: number
}

export const useStatistics = (): StatisticsContextValue => {
	const {aiModels, loading: aiModelsLoading} = useAiModelsContext()
	const {usersInfo, userInfoMap, loading: userAccessLoading} = useUsersAccessContext()
	const {rawLogs, loading: auditingLogsLoading} = useAuditingLogsContext()
	const {groups, allGroupsMembers} = useGroupsContext()
	const {aiPricing} = useAiPricingContext()	

	const [loading, setLoading] = useState<boolean>(true)
	const [usageStatistics, setUsageStatistics] = useState<UsageStatistic | undefined>(undefined)
	const [top5UsersStatistics, setTop5UsersStatistics] = useState<UserUsageStatistic[]>([])
	const [totalPromptsByModel, setTotalPromptsByModel] = useState<StatisticByModel[]>([])
	const [totalUsersByModel, setTotalUsersByModel] = useState<StatisticByModel[]>([])
	const [topModelsByCost, setTopModelsByCost] = useState<AIModelCost[]>([])
	const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>()

	const calculateTop5Users = (userUsageStatistics: UserUsageStatistic[]) => {
		if (!userUsageStatistics.length) return

		const sortedUsersByUsage = [...userUsageStatistics].sort((userUsageStatistic: UserUsageStatistic, userUsageStatistic2: UserUsageStatistic) =>
			userUsageStatistic2.totalPrompts - userUsageStatistic.totalPrompts)
		setTop5UsersStatistics(sortedUsersByUsage.slice(0, 5))
	}

	const getTopAIModelsByCost = useCallback((modelsCosts: Record<AIModelID, number>) => {
		const currentAiModels = aiModels.map(aiModel => ({name: aiModel.name, id: aiModel.id}))
		const modelsArray: AIModelCost[] = Object.entries(modelsCosts).map(([modelId, cost]) => ({
			modelId: modelId as AIModelID,
			modelName: currentAiModels?.find(({id}) => id === modelId)?.name ?? AIModels[modelId as AIModelID].name,
			cost: roundTwoDecimals(cost)
		})).filter(model => aiModels.find(aiModel => aiModel.id === model.modelId)?.isEnabled || model.cost > 0)

		const sortedModels = modelsArray.sort((modelA, modelB) => modelB.cost - modelA.cost)
		const topModels = sortedModels.length <= BUDGET_CHART_DISPLAYED_MODELS
			? sortedModels.slice(0, BUDGET_CHART_DISPLAYED_MODELS)
			: [
				...sortedModels.slice(0, BUDGET_CHART_DISPLAYED_MODELS - 1),
				sortedModels.slice(BUDGET_CHART_DISPLAYED_MODELS).reduce((prev, curr) => ({...prev, cost: prev.cost + curr.cost}), { cost: 0, modelId: 'other', modelName: 'Others' } as AIModelCost)
			]
		setTopModelsByCost(topModels)
	}, [aiModels])

	const calculateStatisticsByModel = (userUsageStatistics: UserUsageStatistic[], usersInfo: UserInfo[], aiModels: AIModel[]) => {
		if (!userUsageStatistics.length) return
		const totalPromptExecutionsByModel: Record<AIModelID, number> = {} as Record<AIModelID, number>
		const totalUsersStatisticsByModel: Record<AIModelID, number> = {} as Record<AIModelID, number>
		aiModels.forEach(model => {
			totalPromptExecutionsByModel[model.id] = 0
			totalUsersStatisticsByModel[model.id] = usersInfo.length
		})
		userUsageStatistics.forEach(userUsageStatistic => {
			aiModels.forEach(aiModel => {
				totalPromptExecutionsByModel[aiModel.id] += userUsageStatistic.totalPromptsByModel[aiModel.id] || 0
			})
		})

		aiModels.forEach(aiModel => {
			totalUsersStatisticsByModel[aiModel.id] -= usersInfo.filter(userInfo => userInfo.disabledAIModels?.includes(aiModel.id)).length ?? 0
		})

		const totalPromptsByModel = aiModels.map(model => ({name: model.name, value: totalPromptExecutionsByModel[model.id]}))
		setTotalPromptsByModel(totalPromptsByModel)
		const totalUsersByModel = aiModels
			.filter(model => model.id !== AIModelID.AMAZON_Q)
			.map(model => ({name: model.name, value: totalUsersStatisticsByModel[model.id]}))
		setTotalUsersByModel(totalUsersByModel.sort((a, b) => b.value - a.value))
	}

	useEffect(() => {
		if (!userAccessLoading && !aiModelsLoading && !auditingLogsLoading) {
			const filteredLogs = dateRangeFilter ? rawLogs.filter(isAuditInfoInDateRange(getAllDayDateRange(dateRangeFilter))) : rawLogs
			setUsageStatistics(
				getUserUsageStatistics(
					aiPricing,
					filteredLogs,
					userInfoMap,
					groups,
					allGroupsMembers.reduce<GroupMember[]>((prev, curr) => [...prev, ...curr.groupMembers], [])
			))
			setLoading(false)
		} else {
			setLoading(true)
		} 
	}, [userAccessLoading, aiModelsLoading, auditingLogsLoading, aiPricing, rawLogs, userInfoMap, groups, allGroupsMembers, dateRangeFilter])

	useEffect(() => {
		if (usageStatistics) {
			const { userUsageStatistics, modelCostStatistics: {totalCostByModel} } = usageStatistics
			calculateTop5Users(userUsageStatistics)
			calculateStatisticsByModel(userUsageStatistics, usersInfo, aiModels)
			getTopAIModelsByCost(totalCostByModel)
		}
	}, [usageStatistics, aiModels, usersInfo, getTopAIModelsByCost])

	return useMemo(() => ({
		loading,
		usageStatistics,
		top5UsersStatistics,
		totalPromptsByModel,
		totalUsersByModel,
		topModelsByCost,
		dateRangeFilter,
		setDateRangeFilter
	}), [loading, usageStatistics, top5UsersStatistics, totalPromptsByModel, totalUsersByModel, topModelsByCost, dateRangeFilter, setDateRangeFilter])
}
