import {createContext, ReactNode, useContext} from 'react'
import {useMarketingComms} from '../hooks/useMarketingComms'

interface MarketingCommsContextProviderProps {
    children: ReactNode
}

export interface MarketingCommsContextValue {
    isMarketingEventSent: boolean
    trackMarketingEvent: (arg? : boolean) => Promise<void>
}

const DEFAULT_MARKETING_COMMS_CONTEXT: MarketingCommsContextValue = {
    isMarketingEventSent: false,
    trackMarketingEvent: () => Promise.resolve()
}

export const MarketingCommsContext = createContext<MarketingCommsContextValue>(DEFAULT_MARKETING_COMMS_CONTEXT)

export const useMarketingCommsContext = () => useContext(MarketingCommsContext)

export const MarketingCommsContextProvider = ({children}: MarketingCommsContextProviderProps) => {
    const value = useMarketingComms()

    return <MarketingCommsContext.Provider value={value}>{children}</MarketingCommsContext.Provider>
}