import {Subscription} from '../types/Stripe'
import {
	GROWTH_AWS_PRICE_ID,
	GROWTH_MONTHLY_PRICE_ID,
	GROWTH_YEARLY_PRICE_ID, SCALE_AWS_PRICE_ID,
	SCALE_MONTHLY_PRICE_ID,
	SCALE_YEARLY_PRICE_ID,
	VALID_STATUS_SUBSCRIPTIONS
} from '../constants/StripeConstants'
import {DateRange} from 'rsuite/DateRangePicker'
import {getDateMonthsAgo} from './dateUtils'
import {getMonthsAuditLimitBasedOnSubscriptionPlan} from './licenseUtils'

export const findTrialSubscription = (subscriptions: Subscription[]): Subscription | undefined =>
	subscriptions.filter(isTrialSubscription)?.[0]

export const isTrialSubscription = (subscription: Subscription): boolean =>
	subscription.status === 'trialing'

export const hasOrganizationValidSubscription = (subscriptions: Subscription[]): boolean =>
	subscriptions.some(isValidSubscription)

export const isValidSubscription = (subscription: Subscription): boolean =>
	VALID_STATUS_SUBSCRIPTIONS.includes(subscription.status)

export const getCurrentSubscription = (subscriptions: Subscription[]): Subscription | undefined =>
	subscriptions.find(subscription => (subscription.current_period_end * 1000) > new Date().getTime())

export const isOrganizationNotPaidAfterTrial = (subscription: Subscription): boolean =>
	subscription.status === 'paused'

export const isGrowthSubscription = (subscription?: Subscription): boolean =>
	!!subscription && [GROWTH_YEARLY_PRICE_ID, GROWTH_MONTHLY_PRICE_ID, GROWTH_AWS_PRICE_ID].includes(subscription.plan.id)

export const isScaleSubscription = (subscription?: Subscription): boolean =>
	!!subscription && [SCALE_YEARLY_PRICE_ID, SCALE_MONTHLY_PRICE_ID, SCALE_AWS_PRICE_ID].includes(subscription.plan.id)

export const isAwsSubscription = (subscription?: Subscription): boolean =>
	!!subscription && [GROWTH_AWS_PRICE_ID, SCALE_AWS_PRICE_ID].includes(subscription.plan.id)

export const getSubscriptionDateRange = (subscription?: Subscription): DateRange | undefined =>
	(isGrowthSubscription(subscription) || isScaleSubscription(subscription))
		? [getDateMonthsAgo(getMonthsAuditLimitBasedOnSubscriptionPlan(subscription)), new Date()]
		: undefined
