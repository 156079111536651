import {AI_MODEL_HEADER_NAMES_MAP, AIModel, AIModelID, ALL_AI_MODEL_IDS, allAIModels} from '../types/AiModel'
import {UserInfo} from '../types/UserInfo'
import {Group} from '../types/Group'
import {hasProp} from '../utils/genericUtils'
import { isModelDisabledGlobally } from '../utils/aiModelUtils'

export const isModelEnabledForUser = (aiModelID: AIModelID, aiModels: AIModel[], userInfo?: UserInfo, userGroup?: Group): boolean => {
	const aiModelEnabled = !!aiModels.find(hasProp('id', aiModelID))?.isEnabled && !isModelDisabledGlobally(aiModelID)
	const userInfoEnabled = !!userInfo?.enabledAIModels?.includes(aiModelID)
	const userInfoDisabled = !!userInfo?.disabledAIModels?.includes(aiModelID)
	const userGroupDisabled = !!userGroup?.disabledAIModels?.includes(aiModelID)
	return aiModelEnabled && !userInfoDisabled && (!userGroupDisabled || (userGroupDisabled && userInfoEnabled))
}

export const getAiModelsList = (storedAiModels: AIModel[]): Array<{ label: string, value: AIModelID}> => {
	const storedModelIds = new Set(storedAiModels.map((model) => model.id))
	return allAIModels
		.filter((aiModel) => !storedModelIds.has(aiModel.id))
		.filter(({ id }) => !isModelDisabledGlobally(id))
		.map((filterModel) => ({label: AI_MODEL_HEADER_NAMES_MAP[filterModel.id], value: filterModel.id}))
}

export const getAiModelsListForEnterpriseCreation = (storedAiModels: AIModel[]): Array<{ label: string, value: AIModelID}> => {
	const storedModelIds = new Set(storedAiModels.map((model) => model.id))
	return allAIModels
		.filter((aiModel) => !storedModelIds.has(aiModel.id))
		.filter(({ id }) => !isModelDisabledGlobally(id))
		.filter(({id}) => ![AIModelID.LLAMA_3_2, AIModelID.MISTRAL].includes(id))
		.map((filterModel) => ({label: AI_MODEL_HEADER_NAMES_MAP[filterModel.id], value: filterModel.id}))
}

export const isAiModel = (value: string): value is AIModelID =>
	ALL_AI_MODEL_IDS.some(id => id === value)